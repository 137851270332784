import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import ReportEvidenceGrid from './partials/ReportEvidenceGrid'
import NewReportEvidenceModal from './partials/NewReportEvidenceModal'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getReportEvidence } from './services'
import { handleError } from '../../helpers'

export default function ServiceReportEvidenceList({ serviceReportId, editable }) {
	const { isConnected } = useSelector(state => state.offline)
	const [reportEvidence, setReportEvidence] = useState(null)
	const [showNewModal, setShowNewModal] = useState(false)

	useEffect(() => {
		!reportEvidence && isConnected && getReportEvidence({
			'filter[service_report_id]': serviceReportId
		})	
			.then(res => setReportEvidence(res.data.data))
			.catch(error => handleError(error))
	}, [ serviceReportId, reportEvidence, isConnected ])

	if(!isConnected) return <InternetRequired />

	if(!reportEvidence) return <Spinner size="small" />
	
	return (
		<React.Fragment>
			<ReportEvidenceGrid 
				reportEvidence={reportEvidence}
				reload={() => setReportEvidence(null)}
				editable={editable}
			/>
			{ editable && (
				<div className='text-right'>
					<p className='text-link' onClick={() => setShowNewModal(true)}>
						Agregar Registro Fotográfico
					</p>
				</div>
			)}
			{ showNewModal && (
				<NewReportEvidenceModal 
					visible
					onClose={() => setShowNewModal(false)}
					serviceReportId={serviceReportId}
					reload={() => setReportEvidence(null)}
				/>
			)}
		</React.Fragment>
	)
}