import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../components/DocumentHeader'

export default function ImpartialityNoticeDocument({ data }) {
	return (
		<Document>
			<DocumentHeader 
				title="RATIFICACIÓN PRINCIPIO DE INDEPENDENCIA"
				version={data.impartiality_notice.version}
				expireAt={data.impartiality_notice.expire_at}
			/>
			<p className='mt-30'>Yo <strong>{data.service_plan.service_inspector.user.name}</strong> mayor de edad, identificado con C.C.: <strong>{data.service_plan.service_inspector.user.document}</strong> como inspector de la Instalación <strong>{data.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch.name}</strong>:</p>
			<p>Ratifico mi declaración de imparcialidad, independencia y confidencialidad la cual suscribí con DEWAR como Organismo de Inspección y ratifico que como colaborador de DEWAR no intervendré en ninguna actividad incompatible con mi independencia de juicio y mi integridad personal y profesional en lo que concierna a mis actividades de inspección, y que en el desempeño de mis funciones contractuales como inspector de las  instalaciones de combustible realizaré análisis y tomaré decisiones que corresponden específicamente a los criterios del Reglamento Técnico, y no a posibles relaciones favorables o desfavorables con  <strong>{data.impartiality_notice.inspector_name}</strong>  entidad relacionada con DEWAR, en cumplimiento de lo establecido en la norma ISO/IEC 17020.</p>
			<p>Como evidencia de mi compromiso firmo el presente documento el <strong>{ moment(data.service_plan.inspected_at).format('DD') } de { moment(data.service_plan.inspected_at).format('MMMM') } de { moment(data.service_plan.inspected_at).format('YYYY') }</strong>.</p>
			<p className='mt-40 pt-40'>______________________________</p>
			<p className='mb-5'>Firma Inspector.</p>
			<p className='mb-5'>Nombre: <strong>{data.service_plan.service_inspector.user.name}</strong></p>
			<p>C.C.: <strong>{data.service_plan.service_inspector.user.document}</strong></p>
			<p className='mt-40'>Recibido por la instalación:</p>
			<p className='mt-40 pt-40'>______________________________</p>
			<p className='mb-5'>Firma</p>
			<p className='mb-5'>Nombre:</p>
			<p>C.C:</p>
		</Document>
	)
}

const Document = styled.div`
	max-width: 980px;
	background-color: #fff;
	padding: 15px 40px;
	margin: 10px auto;
	p {
		font-size: 15px;
		line-height: 20px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
`