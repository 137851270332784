import React from 'react'
import { useHistory } from 'react-router'
import { Button } from 'reactstrap'
import { Descriptions, Empty, Result } from 'antd'
import { Link } from 'react-router-dom'

export default function RecertificationProposalTab({ serviceReport, reload }) {
	const history = useHistory()

	if(!serviceReport.service_veredict.certificate)
		return <Result title="Debe tener un certificado para emitir una propuesta de recertificación" status="warning" />

	return (
		<React.Fragment>
			{ serviceReport.recertification_proposal_id ? (
				<React.Fragment>
					<Descriptions className='mt-20' size="small" bordered>
						<Descriptions.Item label="Propuesta">
							PRN-{serviceReport.recertification_proposal_id}
						</Descriptions.Item>
					</Descriptions>
					<Link to={`/proposals/preview/${serviceReport.recertification_proposal_id}`}>
						<Button color="primary" outline className='mt-30'>
							Ver Propuesta de Recertificación
						</Button>
					</Link> 
				</React.Fragment>
			) : (
				<Empty description="No existe propuesta de recertificación aún" className='pt-30'>
					<Button color="primary" onClick={() => history.push(`/proposals/recertification/wizard/${serviceReport.service_review.service_plan.service_inspector.service.proposal.inquiry.id}/${serviceReport.service_veredict.certificate.id}`)}>
						Enviar Propuesta de Recertifcación
					</Button>
				</Empty>
			)}
		</React.Fragment>
	)
}