import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import RequirementForm from './RequirementForm'
import { storeRequirement } from '../services'
import { handleError } from '../../../helpers'

export default function NewRequirementModal({ reload, typeId }) {
	const [showNewModal, setShowNewModal] = useState(false)
	const { register, handleSubmit, formState: { errors }, reset } = useForm()
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		values.instalation_type_id = typeId
		storeRequirement(values)
			.then(res => {
				message.success(res.data.message)
				reset()
				setShowNewModal(false)
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	const onClose = () => {
		reset()
		setShowNewModal(false)
	}

	return (
		<React.Fragment>
			<Button color="primary" className='mt-0' onClick={() => setShowNewModal(true)}>
				Agregar Documento
			</Button>
			<Modal isOpen={showNewModal} toggle={onClose}>
				<ModalHeader toggle={onClose}>Agregar Documento</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<RequirementForm register={register} errors={errors} />
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Agregar Documento
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}