import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import ServerSideSelect from '../../../components/ServerSideSelect'
import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

import { searchInspectors } from '../../Users/services'
import { currency } from '../../../helpers'

export default function ServiceInspectorForm({ register, errors, watch, setValue, userSelected, setUserSelected }) {
	return (
		<React.Fragment>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
					<FormatVersionSelect
						keyword="serviceInspector"
						setValue={setValue}
					/>
					{ errors.version && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
					<Form.Control {...register('expire_at')} disabled />
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Inspector Asignado <span className='text-danger'>*</span></Form.Label>
				<ServerSideSelect 
					value={userSelected}
					placeholder="Buscar por nombre de inspector"
					fetchOptions={searchInspectors}
					onChange={value => setUserSelected(value)}
					className="full-width mb-10"
				/>
				<div className='text-right mb-5'>
					<Link to="/settings/users" className='small'>Ir a módulo de usuarios</Link>
				</div>
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha envío asignación <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('assigned_at', { required: true })}
					type="date"
				/>
				{ errors.assigned_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Valor Servicio Inspección <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('service_value', { required: true })}
						type="number"
					/>
					{ errors.service_value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Total Gastos de Viaje <span className='text-danger'>*</span></Form.Label>
					<Form.Control 
						{...register('trip_expenses', { required: true })}
						type="number"
					/>
					{ errors.trip_expenses && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Valor Total</Form.Label>
				<Form.Control 
					disabled
					value={`$ ${currency(parseInt(watch('service_value')) + parseInt(watch('trip_expenses')))}`}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Label>Observaciones Específicas <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('observations', { required: true })}
					as="textarea"
					style={{  height: '70px' }}
				/>
				{ errors.observations && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}