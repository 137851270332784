import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap'
import { Redirect } from 'react-router'
import { Result, Tabs } from 'antd'

import ServiceProfileDetails from '../Services/partials/ServiceProfileDetails'

import ServiceReviewTab from '../ServiceReviews/ServiceReviewTab'
import ServiceResolutionTab from '../Services/ServiceResolutionTab'
import VerificationListTabOffline from './VerificationListTabOffline'
import NonCompliantTabOffline from './NonCompliantTabOffline'

const { TabPane } = Tabs

export default function ServiceProfileOffline(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.match.params.id
	const service = useSelector(state => state.services.data.filter(r => r.id === parseInt(id))[0])
	const [currentTab, setCurrentTab] = useState('acta')
	const [filters, setFilters] = useState({ 
		onlyUncompliant: false,
		pendingTasks: {
			periods: []
		}
	})

	if(isConnected) return <Redirect to={`/services/${id}`} />

	if(!service) return <Result status="warning" title="El servicio no se encuentra disponible para Offline" />

	const reload = () => {}

	const tabProps = {
		service,
		reload,
		filters,
		setFilters
	}

	return (
		<React.Fragment>
			<Card>
				<CardBody>
					<CardTitle>Servicio de Inspección #{service.id} - OFFLINE</CardTitle>
					<CardSubtitle>Propuesta: {service.proposal.sku} | Inspección {service.type}</CardSubtitle>
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<Tabs tabPosition='left' type="card" activeKey={currentTab} onChange={key => setCurrentTab(key)}>
						<TabPane key="asignacion" tab="1. Asignación de Inspección" disabled/>
						<TabPane key="notificacion" tab="2. Notificación de Inspección" disabled />
						<TabPane key="plan" tab="3. Plan de Inspección" disabled />
						<TabPane key="acta"
							tab="4. Acta de Inspección" 
							disabled={!(service.service_inspector && service.service_inspector.service_plan)}
						>
							<h6 className='mt-10 mb-0 text-center'>Acta de Inspección</h6>
							<ServiceReviewTab {...tabProps} />
						</TabPane>
						<TabPane key="lista"
							tab="5. Lista de Verificación" 
							disabled={!(service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review)}
						>
							<h6 className='mt-10 mb-0 text-center'>Lista de Verificación</h6>
							<VerificationListTabOffline {...tabProps} />
						</TabPane>
						{ service.type === 'Inicial' ? (
							<TabPane key="evidencias" tab="6. Evidencias Relacionadas" disabled />
						) : (
							<TabPane key="evidencias" tab="6. Evidencias No Conformidades" disabled />
						)}
						<TabPane key="noconformidades"
							tab="7. Reporte No Conformidades" 
							disabled={!(service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review && service.verification_list)}
						>
							<h6 className='mt-10 mb-0 text-center'>Reporte de No Conformidades</h6>
							{ currentTab === 'noconformidades' && <NonCompliantTabOffline {...tabProps} /> }
						</TabPane>
						<TabPane key="informe"
							tab="8. Resultado de Inspección" 
							disabled={!(service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review && service.service_inspector.service_plan.service_review.non_compliant_report && service.verification_list)}
						>
							<h6 className='mt-10 mb-0 text-center'>Resultado de Inspección</h6>
							<ServiceResolutionTab {...tabProps} />
						</TabPane>
						<TabPane key="retrynotice" tab="9. Carta Inspección de Cierre" disabled />
					</Tabs>
				</CardBody>
			</Card>
			<ServiceProfileDetails service={service} />
		</React.Fragment>
	)
}