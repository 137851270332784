import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Descriptions, Divider } from 'antd'
import moment from 'moment'

import ServiceReportEvidenceList from '../ReportEvidence/ServiceReportEvidenceList'
import SendNotificationButton from '../../components/SendNotificationButton'
import { notifyServiceReport } from './services'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'

const { Item } = Descriptions

export default function ServiceReportTab({ serviceReport, reload }) {
	const [emailLogsModal, setEmailLogsModal] = useState(null)

	return (
		<React.Fragment>
			<Descriptions bordered size="small" className='mt-15' column={2}>
				<Item label="Versión">
					{serviceReport.version}
				</Item>
				<Item label="Fecha Vigencia">
					{moment(serviceReport.expire_at).format('DD-MMM-YYYY').toUpperCase()}
				</Item>
			</Descriptions>
			<p className='bold mt-20'>REGISTRO FOTOGRÁFICO</p>
			<ServiceReportEvidenceList
				serviceReportId={serviceReport.id}
				editable={serviceReport.is_editable}
			/>
			<Link to={`/service_reports/preview/${serviceReport.id}`}>
				<Button color="primary" outline>
					Formato de Informe de Inspección
				</Button>
			</Link>
			<Divider type="vertical" />
			<SendNotificationButton notifyService={() => notifyServiceReport(serviceReport.id)} />
			<Divider type="vertical" />
			<Button color="primary" outline onClick={() => setEmailLogsModal(serviceReport.id)}>
				Correos Enviados
			</Button>

			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="service_report"
					modelId={emailLogsModal}
				/>
			)}
		</React.Fragment>
	)
}