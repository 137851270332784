import React from 'react'
import styled from 'styled-components'

import DocumentHeader from './DocumentHeader'
import DocumentFooter from './DocumentFooter'
import DocumentEdsSpecs from './DocumentEdsSpecs'
import DocumentExtraTanks from './DocumentExtraTanks'

export default function CertificateDocument({ data, filters }) {
	const serviceReview = data.service_veredict.service_report.service_review
	const serviceInspector = serviceReview.service_plan.service_inspector
	const branch = serviceInspector.service.proposal.inquiry.branch_spec.branch

	const totalPages = serviceReview.tank_specs.length <= 7 ? 1 : ( serviceReview.tank_specs.length <= 27 ? 2 : (serviceReview.tank_specs.length <= 47 ? 3 : (serviceReview.tank_specs.length <= 67 ? 4 : 5)) )

	return (
		<React.Fragment>
			<Document 
				className='certificateContainer' 
				hideBackground={filters.hideBackground}
				isOfficial={filters.isOfficial}
			>
				<div className='clearfix mb-20' />
				<div className='text-center'>
					<DocumentHeader 
						data={data} 
						branch={branch} 
						filters={filters}
					/>

					<p className='mb-10'>El cual se otorga en cumplimiento de los requisitos establecidos en la {data.article_numerals}</p>

					<p className='mb-7'>Descripción de la infraestructura inspeccionada:</p>

					<DocumentEdsSpecs data={data} branch={branch} />

					<div className='main_table'>
						<table className='full-width border text-center table-small'>
							<tbody>
								{ branch.instalation_type_name.includes('Planta') && (
									<tr>
										<th colSpan={2}>Cantidad de Tanques</th>
										<th>{parseInt(serviceReview.total_tanks_underground) + parseInt(serviceReview.total_tanks_ground)}</th>
									</tr>
								)}
								<tr className='bold'>
									<th className='border bg-light'>Tanque</th>
									<th className='border bg-light'>Producto Almacenado</th>
									<th className='border bg-light'>Capacidad (gal)</th>
								</tr>
								{ serviceReview.tank_specs.map((tank, i) => i < 7 && (
									<tr key={i}>
										<td className='border'>
										{tank.tank_number}
										</td>
										<td className='border'>
											{tank.other_product_name ? tank.other_product_name : tank.product_name}
										</td>
										<td className='border'>
											{tank.tank_capacity}
										</td>
									</tr>
								)) }
								{ serviceReview.tank_specs.length <= 7 ? (
									<tr>
										<td colSpan={2} className='border bold text-right pr-5'>Capacidad Total</td>
										<td className='bold'>
											{ data.tanks_total_capacity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
										</td>
									</tr>
								) : (
									<tr>
										<td colSpan={3}>Nota: Otros tanques ver anexos</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>

					<div className='details_container text-left mt-2 mb-5'>
						<p className='small'>Observaciones: {data.observations ? data.observations : <em>Sin observaciones</em>}</p>
					</div>

					<DocumentFooter 
						filters={filters} 
						data={data} 
						page={1} 
						pageTotal={totalPages}
					>
						<div className='text-center mb-10'>
							<p style={{ fontSize:'13px' }} className='bold mb-5'>Edición {data.modifications_count ? parseInt(data.modifications_count) + 1 : 1} – Emisión del Certificado. {data.version}</p>
							<p className='xs'>La vigencia del certificado es de 2 años a partir de la fecha de emisión, está determinada por el numeral 13.1 de la Resolución 40198 de 2021 o aquella que lo modifique o sustituya. Este certificado está sujeto a lo establecido en el documento DN5 Reglamento de Inspección. El presente certificado demuestra el cumplimiento de la instalación con base en la infraestructura señalada, los cambios realizados después de la fecha de inspección inicial deben ser reportados al Ministerio de Minas y Energía. Para verificar validez del certificado comunicarse Celular 3163702077; organismo@dewar.com.co</p>						
						</div>
					</DocumentFooter>
				</div>
			</Document>
			{ serviceReview.tank_specs.length > 7 && (
				<Document
					className='certificateContainer' 
					hideBackground={filters.hideBackground}
					isOfficial={filters.isOfficial}
				>
					<DocumentExtraTanks 
						filters={filters}
						data={data}
						branch={branch}
						serviceReview={serviceReview}
						page={2}
						pageTotal={totalPages}
					/>
				</Document>
			)}
			{ serviceReview.tank_specs.length > 27 && (
				<Document
					className='certificateContainer' 
					hideBackground={filters.hideBackground}
					isOfficial={filters.isOfficial}
				>
					<DocumentExtraTanks 
						filters={filters}
						data={data}
						branch={branch}
						serviceReview={serviceReview}
						page={3}
						pageTotal={totalPages}
					/>
				</Document>
			)}
			{ serviceReview.tank_specs.length > 47 && (
				<Document
					className='certificateContainer' 
					hideBackground={filters.hideBackground}
					isOfficial={filters.isOfficial}
				>
					<DocumentExtraTanks 
						filters={filters}
						data={data}
						branch={branch}
						serviceReview={serviceReview}
						page={4}
						pageTotal={totalPages}
					/>
				</Document>
			)}
		</React.Fragment>
	)
}

const Document = styled.div`
	width: 216mm;
	min-height: 279mm;
	padding: 0.75in 0.75in 0 0.75in;
	background-image: ${props => !props.hideBackground ? ( props.isOfficial ? `url('/images/fondo_de_agua.jpg')` : `url('/images/fondo_de_agua_borrador.jpeg')` ) : ''};
	background-size: cover;
	background-repeat-x: no-repeat;
	background-color: #fff;
	display: flex;
	font-family: "Arial Narrow", Arial, sans-serif;
	h1 {
		font-size: 21px;
		margin-bottom: 0px;
		font-weight: bold
	}
	h5 {
		font-size: 16px;
		margin-bottom: 0px;
	}
	p {
		font-size: 14px;
		margin-bottom: 0px;
	}
	p.small {
		font-size: 13px;
	}
	p.xs {
		font-size: 12px;
	}
	td, th {
		font-size: 13px;
		height: 15px;
	}
	.details_container {
		padding: 5px;
		border: 1px solid #888;
		margin-bottom: 10px;
	}
	.ant-table-content {
		margin-top: 7px;
	}
	.sm-width {
		max-width: 550px;
		margin: auto;
	}
	td.border {
		padding: 0px;
  	}
	svg {
		vertical-align: unset;
	}
	.additional_tanks {
		height: 205mm;
		margin: 35px 0px;
	}
	.bg-light {
		background-color: #f2f2f2;
		font-weight: bold;
	}
	.img-firma {
		width: 90px;
		height: auto;
	}
	.border {
		border: 1px solid #919191!important;
  	}
  	.main_table {
		min-height: 220px;
  	}
  	.logoDewarOnac {
		width: 7.7cm;
  	}
  	.logoDiv {
		width: 7.9cm;
		background-color: white;
		border: 4px solid white;
		position: relative;
		top: -50px;
		left: 420px;
		margin-bottom: -50px;
  	}
`