import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import ServiceForm from './ServiceForm'

import { storeService } from '../services'
import { handleError } from '../../../helpers'

export default function NewRetryServiceModal({ visible, onClose, service, reload, setCurrentTab }) {
	const { register, handleSubmit, formState: { errors } } = useForm()
	const [loading, setLoading] = useState(false)
	const history = useHistory()

	const onSubmit = values => {
		setLoading(true)
		setCurrentTab('asignacion')
		storeService({
			proposal_id: service.proposal_id,
			initial_service_id: service.id,
			...values,
		})
			.then(res => {
				message.success(res.data.message)
				history.push(`/services/${res.data.data.id}`)
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Abrir Inspección de Cierre</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServiceForm register={register} errors={errors} service={service} />
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Abrir Inspección de Cierre
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}