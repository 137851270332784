import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Empty, Descriptions, Divider } from 'antd'
import moment from 'moment'

import NewDecisionNoticeModal from './partials/NewDecisionNoticeModal'
import SendNotificationButton from '../../components/SendNotificationButton'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'

import { notifyDecisionNotice } from './services'

const { Item } = Descriptions

export default function DecisionNoticeTab({ serviceReport, reload }) {
	const [showNewModal, setShowNewModal] = useState(false)
	const [emailLogsModal, setEmailLogsModal] = useState(null)

	const decisionNotice = serviceReport.service_veredict.decision_notice

	return (
		<React.Fragment>
			{ decisionNotice ? (
				<React.Fragment>
					<Descriptions bordered size="small" className='mt-20 mb-15' column={2}>
						<Item label="Versión">
							{decisionNotice.version}
						</Item>
						<Item label="Fecha de Vigencia">
							{moment(decisionNotice.expire_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
						<Item label="Fecha del Comunicado">
							{moment(decisionNotice.published_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
					</Descriptions>
					<Link to={`/decision_notices/preview/${decisionNotice.id}`}>
						<Button color="primary" outline>
							Ver Comunicado de Decisión
						</Button>
					</Link>
					<Divider type="vertical" />
					<SendNotificationButton notifyService={()=>notifyDecisionNotice(decisionNotice.id)} />
					<Divider type="vertical" />
					<Button color="primary" outline onClick={() => setEmailLogsModal(decisionNotice.id)}>
						Correos Enviados
					</Button>
				</React.Fragment>
			) : (
				<Empty description="No se ha emitido el comunicado de decisión aún" className='pt-30'>
					<Button color="primary" onClick={() => setShowNewModal(true)}>
						Emitir Comunicado de Decisión
					</Button>
				</Empty>
			)}
			{ showNewModal && (
				<NewDecisionNoticeModal 
					visible
					onClose={() => setShowNewModal(false)}
					serviceVeredict={serviceReport.service_veredict}
					noCompliant={!serviceReport.is_committee_compliant}
					reload={reload}
				/>
			)}
			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="decision_notice"
					modelId={emailLogsModal}
				/>
			)}
		</React.Fragment>
	)
}