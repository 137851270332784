import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../components/DocumentHeader'
import { currency } from '../../../helpers'

export default function ProposalDocument({ data }) {
	return (
		<React.Fragment>
			<Document>
				<DocumentHeader
					title="PROPUESTA COMERCIAL"
					version={data.version ? data.version : '_______'}
					expireAt={data.expire_at ? data.expire_at : '_________'}
					proposalSku={data.id ? data.sku : null}
				/>
				<p className='text-right mb-20'><strong>Elaborada el:</strong> {moment(data.elaborated_at).format('DD-MMM-YYYY').toUpperCase()}</p>
				<p className='mb-5'>Señores:</p>
				<p className='mb-5'><strong>{data.inquiry.branch_spec.branch.company.legal_owner_name}</strong></p>
				<p className='mb-5'><strong>INSTALACIÓN {data.inquiry.branch_spec.branch.name}</strong></p>
				<p className='mb-5'>{data.inquiry.branch_spec.branch.address_line}</p>
				<p className='mb-20'>{data.inquiry.branch_spec.branch.address_city}</p>
				{/* <p className='mb-30'><strong>Señores:</strong> {data.employee_name} ({data.employee_position}) - {data.branch_name}</p> */}
				<h6>PRESENTACIÓN</h6>
				<p>En DEWAR SAS contamos con acreditación ONAC, vigente a la fecha, con código de acreditación 21-OIN-008 bajo la norma ISO/IEC 17020:2012.</p>
				<p>Agradecemos, haber elegido a DEWAR como el Organismo de Inspección de su instalación y les garantizamos que además de la competencia, capacidad y experiencia, contamos con calidad, eficacia, independencia, transparencia y confidencialidad en los procesos que nos sean asignados.</p>
				<p>Es parte integral de esta propuesta comercial “El Reglamento de Inspección” de DEWAR, el cual se adjunta a esta propuesta, para total conocimiento de las regulaciones del proceso, de los derechos y deberes de las partes, y de los requisitos de la inspección.  En el momento de aceptación de esta propuesta usted declarará conocer y aceptar este Reglamento, tal como se establece en la carta de su aceptación y de esta forma se establece un acuerdo legalmente ejecutable entre las partes.</p>
				<p>De acuerdo con la Solicitud de Servicio diligenciada por su empresa y recibida en DEWAR, la cual ha sido revisada y aprobada en relación con nuestro alcance de inspección,  presentamos la propuesta comercial para la prestación del servicio de inspección así:</p>
				<h5>1. PROPUESTA TÉCNICA</h5>
				<h6>1.1 OBJETIVO</h6>
				<p>Adelantar la inspección de la Instalación <span className="variable">{data.branch_name}</span></p>
				<h6>1.2 ALCANCE</h6>
				<p>DEWAR suministra personal calificado, la metodología y el procedimiento correcto, para llevar a buen término el proceso de inspección de la instalación, tal como corresponde a sus funciones y responsabilidades como Organismo de Inspección.	Para el proceso de inspección <span className="variable">{data.branch_name}</span>, ubicada <span className="variable">{data.branch_address}</span> en <span className="variable">{data.branch_state}</span> <span className="variable">{data.branch_municipio}</span>; DEWAR realizará inspección para <span className="variable">{data.branch_type_name}</span> de combustible líquido, sujeto de evaluación de la conformidad según lo registró en la solicitud de servicio presentada a DEWAR como organismo de inspección.</p>
				<h6>1.3 CRITERIO</h6>
				<p>DEWAR como organismo de inspección acreditado y dando cumplimiento a los estándares establecidos en la NTC-ISO/IEC 17020 vigente, adelanta el proceso de inspección conforme con los requisitos documentales y técnicos (como instalaciones, seguridad, infraestructura, equipos etc.) exigidos por <strong>{data.branch_type_criteria}</strong>, expedidas por Ministerio de Minas y Energía., por la cual se expide el reglamento técnico aplicable a las Estaciones de Servicio, Plantas de Abastecimiento, Instalaciones del Gran Consumidor con Instalación Fija, que almacenen y manejen crudos y/o combustibles líquidos y mezclas de los mismos con biocombustibles, excepto GLP.</p>
				<h5>2. PROCESO DE INSPECCIÓN</h5>
				<p>Una vez aceptada esta propuesta, se asignará el inspector según capacidades y competencia, además de los criterios de independencia e imparcialidad, y en la notificación de la inspección se informará la programación para realizar el proceso de inspección.</p>
				<p>De acuerdo con el Plan de inspección, que el Cliente recibirá con antelación a la fecha de programación, el inspector visitará las instalaciones para hacer la verificación del cumplimiento del Reglamento Técnico. </p>
				<p>Para el día de la actividad de inspección es requerido que el Cliente cuente con la disponibilidad del personal encargado de atender la inspección y autorizado para el manejo de los equipos requeridos para la inspección.</p>
				<p>Una vez se desarrolle la evaluación de la instalación, basada en las listas de verificación del Reglamento Técnico, el inspector se tomará un tiempo para estudiar sus registros y redactar, si es del caso, los hallazgos no conformes identificados, finalmente realizará la reunión de cierre de la inspección, donde entregará el registro de ejecución de la visita con los resultados de esta.</p>
				<p>Si se detectan no conformidades, la instalación podrá solicitar una inspección de cierre, dentro de los siguientes (90) días calendario posteriores a la reunión de cierre de la inspección inicial, para cada proceso de inspección solo se puede realizar una inspección de cierre donde se debe evidenciar la solución eficaz de las no conformidades identificadas en la inspección inicial. Dependiendo de las especificaciones de la inspección de cierre, esta puede requerir un cobro adicional la cual será notificada al Cliente previa a su programación.</p>
				<p>Una vez el inspector cuente con los resultados del proceso de inspección, se los comunicará al Cliente y cuenta con máximo 3 días calendario para presentar el informe de inspección al Comité Técnico, quien revisará y tomará una decisión frente a la declaración de conformidad del reglamento técnico correspondiente.</p>
				<p>La decisión es comunicada al Cliente y a la autoridad competente, de declararse la conformidad, se emite el certificado de inspección correspondiente.</p>
				<p>Las fechas consignadas en el certificado de inspección corresponden a los días que la instalación fue inspeccionada, y el día que el certificado es emitido. El Ministerio de Minas y Energía es la autoridad competente, quien indicará la frecuencia requerimientos de realizar una nueva inspección de acuerdo al cumplimiento del Reglamento técnico, modificaciones, entre otras.</p>
				<h5>3. PROPUESTA ECONÓMICA</h5>
				<p>El valor total de la Propuesta de Servicios es de <span className="variable">$ {currency(data.total)}</span> <span className="variable">{data.text_value ? data.text_value : '_________'}</span> tal como se detalla a continuación:</p>
				<table className='mb-20 full-width table'>
					<thead>
						<tr>
							<th colSpan={2} className='text-center'>COSTOS DEL PROCESO DE INSPECCIÓN</th>
						</tr>
						<tr>
							<th>ACTIVIDAD</th>
							<th>VALOR</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Servicio del proceso de inspección</td>
							<td><span className="variable">$ {currency(data.service_value)}</span></td>
						</tr>
						<tr>
							<td>Gastos de viaje</td>
							<td><span className="variable">$ {currency(data.trip_expenses_value)}</span></td>
						</tr>
						<tr><td colSpan={2}></td></tr>
						<tr>
							<td>Total del servicio antes de IVA</td>
							<td><span className="variable">$ {currency(data.total)}</span></td>
						</tr>
					</tbody>
				</table>
				<p><span className="variable">{data.price_terms}</span></p>
				<h5>4. APROBACIÓN PROPUESTA Y FORMA DE PAGO </h5>
				<ul>
					<li>El Cliente debe conocer y acoger el Reglamento de inspección.</li>
					<li>El Cliente deberá enviar firmada por el Representante Legal la carta de aceptación adjunta con este documento, junto con el RUT y el Certificado de Cámara de comercio.</li>
					<li>En el momento del envío de la carta de aceptación se acordará la programación, dependiendo de la fecha de pago del servicio y la disponibilidad del inspector.</li>
					<li>El envío de la notificación de inspección se debe poder realizar mínimo 5 días antes de la fecha de la visita a la instalación.</li>
					<li>La cuenta de cobro/factura se elaborará conforme a los datos relacionados en el RUT y Certificado de Cámara y Comercio, suministrados por el Cliente.</li>
					<li>La cuenta de cobro/factura incluirá el valor por los servicios del proceso de inspección, más el valor de los gastos de viaje, necesario para la prestación del servicio.</li>
					<li>El pago del servicio se debe realizar antes de la programación de la inspección, por el valor total.</li>
					<li>El pago del servicio se debe realizar únicamente en la cuenta de ahorros de Bancolombia del DEWAR S.A.S, cuya información se encuentra consignada en las cuentas de cobro/factura.</li>
					<li>El Cliente deberá enviar el soporte de pago a DEWAR para continuar con el proceso de programación. </li>
					<li>Una vez realizado el pago, si el Cliente desiste del proceso de inspección sin motivo de incumplimiento por parte DEWAR de las condiciones de esta propuesta, no se realizará devolución del dinero.</li>
				</ul>
				<h5>5. VALIDEZ DE LA PROPUESTA</h5>
				<p>La presente propuesta tiene una validez de quince (15) días calendario, en caso de efectuar el pago luego del término establecido, pueden aplicar modificaciones, por concepto de tiquetes aéreos y alojamiento del inspector en los casos en que se requiera.</p>
				<p>Los costos de inspección están establecidos bajo el cumplimiento del Plan de Inspección del proceso según el tipo de instalación, en caso de presentarse inconvenientes o retrasos por parte del cliente en el proceso de recibo de la inspección o de presentación de documentos, se generará cargos adicionales por concepto del tiempo del Inspector.</p>
				<h5>6. TÉRMINOS GENERALES</h5>
				<ul>
					<li>DEWAR realiza el proceso de inspección de manera imparcial e independiente de entidades relacionadas dedicadas a la propiedad, diseño, construcción / fabricación, suministro, instalación o mantenimiento de instalaciones de combustible. Las decisiones que se tomen en los procesos de inspección corresponden específicamente a la verificación de cumplimiento de los requisitos del Reglamento Técnico objeto de evaluación.</li>
					<li>DEWAR asegura la confidencialidad de la información de la instalación recibida antes, durante y después del proceso de inspección, excepto que una autoridad competente requiera que se divulgue información considerada como confidencial, en cuyo caso se informará a la organización dueña o responsable de la instalación.</li>
					<li>Si como resultado del proceso de inspección se emite el certificado de inspección, éste se fundamentará en las condiciones y el estado de la instalación en el momento de la inspección por tanto en el caso que se realicen modificaciones a la instalación certificada, se debe comunicar a DEWAR las modificaciones realizadas y ejecutar una nueva inspección.</li>
					<li>La documentación del proceso será recibida en medio digital.</li>
					<li>DEWAR cuenta con un procedimiento para dar tratamiento quejas y apelaciones, el cual está disponible bajo solicitud. Así mismo, bajo solicitud le informaremos sobre demás procedimientos que hacen parte del proceso de inspección.</li>
					<li>Los horarios de atención en las oficinas de DEWAR son de 8:00 am a las 5:00 pm días hábiles de lunes a viernes.</li>
				</ul>
				<p>Agradecemos, solicitarnos la información que consideren necesaria para llevar a buen término el proceso de la referencia a su más pronta conveniencia.</p>
				<p className='mb-0'>Oscar Alberto Domínguez</p>
				<p className='mb-0'>Representante Legal</p>
				<p>DEWAR  - OIN</p>
				<p className='text-center mt-30'>
					Carrera 61 # 96 A 23 Oficina 201 Barrio Andes, Bogotá – Colombia. Tel (601) 3001153 Cel. 3163702077
				</p>
				<p className='text-center mt-10'>E-mail. comercial@dewar.com.co</p>
			</Document>
		</React.Fragment>
	)
}

const Document = styled.div`
	margin-bottom: 50px;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #333;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
`