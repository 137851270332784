import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'

import AcceptanceDocument from './partials/AcceptanceDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { publicGetProposal } from './services'
import { handleError } from '../../helpers'

export default function AcceptancePreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [proposal, setProposal] = useState(null)

	useEffect(() => {
		!proposal && isConnected && publicGetProposal(id, {
			include: 'inquiry.branchSpec.branch.company'
		})
			.then(res => setProposal(res.data.data))
			.catch(error => handleError(error))
	}, [proposal, id, isConnected])

	if(!isConnected) return <InternetRequired />

	return (
		<Container>
			{ proposal ? (
				<AcceptanceDocument data={proposal} />
			) : (
				<Spinner />
			)}
		</Container>
	)
}

const Container = styled.div`
	padding: 5px 10px;
`