import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import NonCompliantPreviewOffline from './NonCompliantPreviewOffline'

import ServiceProfileOffline from './ServiceProfileOffline'
import ServiceReviewPreviewOffline from './ServiceReviewPreviewOffline'
import ServicesListOffline from './ServicesListOffline'

export default function Offline() {
	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/offline/services" component={ServicesListOffline} />
				<Route exact path="/offline/services/:id" component={ServiceProfileOffline} />
				<Route 
					exact 
					path="/offline/service_reviews/preview/:serviceId/:reviewId" 
					component={ServiceReviewPreviewOffline} 
				/>
				<Route 
					exact 
					path="/offline/non_compliant_reports/preview/:serviceId/:id" 
					component={NonCompliantPreviewOffline} 
				/>

				<Redirect to="/404" />
			</Switch>
		</React.Fragment>
	)
}