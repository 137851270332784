import React from 'react'

import logo from '../../../assets/images/logo_dewar_onac.png'

export default function DocumentHeader({ data, branch, filters }) {
	return (
		<React.Fragment>
			{ filters.isOfficial && !filters.hideBackground && (
				<div className='logoDiv'>
					<img src={logo} alt="Logo Dewar y ONAC" className='logoDewarOnac' />
				</div>
			)}
			<h1>DEWAR SAS</h1>
			<p className='sm-width small mb-7'>En DEWAR SAS contamos con acreditación ONAC, vigente a la fecha, con código de acreditación 21-OIN-008, bajo la norma internacional ISO/IEC 17020:2012</p>
			<h5 className='mb-7'>Como Organismo de Inspección concede a:</h5>
			<h5 className='bold'>{data.company_name.toUpperCase()}</h5>
			<h5 className='mb-7'>NIT. {data.company_document}</h5>
			<h5 className='bold'>{branch.name.toUpperCase()}</h5>
			<p className='mb-7'>{branch.address_line}; {branch.address_city}, {branch.address_state}</p>
			<p className='mb-7'><strong>CÓDIGO SICOM</strong> {data.company_sicom}</p>
			<h5 className='bold'>CERTIFICADO DE INSPECCIÓN</h5>
			<p className='sm-width mb-7'>{branch.instalation_type_name}</p>
			<h5 className='mb-7 bold'>{data.sku_formated}</h5>
		</React.Fragment>
	)
}