import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'
import { Redirect } from 'react-router'

import ListTopBar from '../../components/ListTopBar'
import ServicesTable from '../Services/partials/ServicesTable'

export default function ServicesListOffline() {
	const { isConnected } = useSelector(state => state.offline)
	const services = useSelector(state => state.services.data)

	if(isConnected) return <Redirect to="/services" />

	return (
		<React.Fragment>
			<ListTopBar 
				title="Servicios de Inspección - OFFLINE"
				subtitle="Visualizando únicamente servicios disponibles offline"
			/>
			<Card>
				<CardBody>
					<ServicesTable services={services} />
				</CardBody>
			</Card>
		</React.Fragment>
	)
}