import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Descriptions, Divider, Empty } from 'antd'
import moment from 'moment'

import NewServiceNotificationModal from './partials/NewServiceNotificationModal'
import SendNotificationButton from '../../components/SendNotificationButton'
import { notifyServiceNotification } from './services'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'

const { Item } = Descriptions

export default function ServiceNotificationTab({ service, reload }) {
	const user = useSelector(state => state.auth.user)
	const [showNewModal, setShowNewModal] = useState(false)
	const [emailLogsModal, setEmailLogsModal] = useState(null)

	const serviceNotification = service.service_inspector.service_notification || null

	return (
		<React.Fragment>
			{ serviceNotification ? (
				<React.Fragment>
					<Descriptions bordered className='pt-10 mb-15' size='small' column={2}>
						<Item label="Fecha envío notificación" span={2}>
							{moment(serviceNotification.notified_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
						<Item label="Versión">
							{serviceNotification.version}
						</Item>
						<Item label="Fecha Vigencia">
							{moment(serviceNotification.expire_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
						<Item label="Coordinador de Programación" span={2}>
						{serviceNotification.sign_by}
						</Item>
					</Descriptions>
					<Link to={`/service_notifications/preview/${serviceNotification.id}`}>
						<Button color="primary" outline>Formato de Notificación de Inspección</Button>
					</Link>
					<Divider type="vertical" />
					<SendNotificationButton 
						notifyService={() => notifyServiceNotification(serviceNotification.id)}
					/>
					<Divider type="vertical" />
					<Button color="primary" outline onClick={() => setEmailLogsModal(serviceNotification.id)}>
						Correos Enviados
					</Button>
				</React.Fragment>
			) : (
				<Empty description="No se ha notificado la inspección aún" className='pt-50'>
					{ user.role !== 'inspector' && (
						<Button color="primary" onClick={() => setShowNewModal(true)}>
							Notificar Inspección
						</Button>
					)}
				</Empty>
			)}
			{ showNewModal && (
				<NewServiceNotificationModal 
					visible
					onClose={() => setShowNewModal(false)}
					service={service}
					reload={reload}
				/>
			)}
			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="service_notification"
					modelId={emailLogsModal}
				/>
			)}
		</React.Fragment>
	)
}