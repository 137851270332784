import React from 'react'
import { Empty, Table } from 'antd'
import moment from 'moment'

export default function ReviewEquipmentsTable({ reviewEquipments }) {

	const columns = [
		{
			title: 'Item',
			dataIndex: 'item'
		},
		{
			title: 'Equipo',
			dataIndex: 'name'
		},
		{
			title: 'Marca',
			dataIndex: 'brand'
		},
		{
			title: 'Serial',
			dataIndex: 'serial'
		},
		{
			title: 'Fecha de calibración / verificación',
			dataIndex: 'verified_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={reviewEquipments}
				columns={columns}
				loading={!reviewEquipments}
				pagination={false}
				locale={{ emptyText: <Empty description="No tiene equipos asociados aún" imageStyle={{ height: '70px' }} /> }}
				rowKey={r => r.id}
				size="small"
				className='mb-20'
			/>
		</React.Fragment>
	)
}