import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import { deleteRequirement } from '../services'
import { handleError } from '../../../helpers'
import EditRequirementModal from './EditRequirementModal'

export default function RequirementsTable({ requirements, reload }) {
	const currentUser = useSelector(state => state.auth.user)
	const [editRequirement, setEditRequirement] = useState(null)

	const handleDelete = id => {
		deleteRequirement(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'Nombre',
			dataIndex: 'name',
		},
		{
			title: 'Descripción Corta',
			dataIndex: 'hint',
			render: t => t ? t : <em className='hint-text'>Sin descripción corta</em>
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Editar">
						<div className='inline' onClick={()=>setEditRequirement(record)}>
							<FontAwesomeIcon className="text-link" icon={faEdit} />{" "}
							<span className='text-link'>Editar</span>
						</div>
					</Tooltip>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar el requerimiento?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(record.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
								<span className='text-link'>Eliminar</span>
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				rowKey={r => r.id}
				dataSource={requirements}
				size='small'
				columns={columns}
				expandable={{
					expandedRowRender: r => <p className='mb-0'><strong>Descripción Larga:</strong> {r.description ? r.description : <em className='hint-text'>Sin descripción larga</em>}</p>
				}}
			/>
			{ editRequirement && (
				<EditRequirementModal 
					visible
					onClose={() => setEditRequirement(null)}
					requirement={editRequirement}
					reload={reload}
				/>
			)}
		</React.Fragment>
	)
}