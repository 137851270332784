import React from 'react'
import { Form } from 'react-bootstrap'

export default function UserEquipmentForm({ register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Ítem</Form.Label>
				<Form.Control 
					{...register('item', { required:true })}
					placeholder='Escriba aquí...'
					type="number"
				/>
				{ errors.item && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Equipo</Form.Label>
				<Form.Control 
					{...register('name', { required:true })}
					placeholder='Escriba aquí...'
				/>
				{ errors.name && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Marca</Form.Label>
				<Form.Control 
					{...register('brand', { required:true })}
					placeholder='Escriba aquí...'
				/>
				{ errors.brand && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Serial</Form.Label>
				<Form.Control 
					{...register('serial', { required:true })}
					placeholder='Escriba aquí...'
				/>
				{ errors.serial && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha de calibración / verificación</Form.Label>
				<Form.Control 
					{...register('verified_at', { required:true })}
					placeholder='Escriba aquí...'
					type="date"
				/>
				{ errors.verified_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}