import React from 'react'
import { Form } from 'react-bootstrap'

export default function UserForm({ user, register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre Completo <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', {required:true})} defaultValue={user && user.name} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Documento de Identidad <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('document', {required:true})} defaultValue={user && user.document} />
				{ errors.document && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Correo Electrónico <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('email', {required:true})} defaultValue={user && user.email} />
				{ errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Teléfono Celular <small>(opcional)</small></Form.Label>
				<Form.Control {...register('mobile', {required:false})} defaultValue={user && user.mobile} />
				{ errors.mobile && <Form.Text className="text-warning">Ingrese únicamente digitos.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Teléfono Secundario <small>(opcional)</small></Form.Label>
				<Form.Control {...register('phone', {required:false})} defaultValue={user && user.phone} />
				{ errors.phone && <Form.Text className="text-warning">Ingrese únicamente digitos.</Form.Text> }
			</Form.Group>
			{ !user && (
				<React.Fragment>
					<Form.Group>
						<Form.Label>Contraseña <span className="text-danger">*</span></Form.Label>
						<Form.Control {...register("password", {required:true})} placeholder="Mínimo 6 digitos" type="password" />
						{ errors.password && <Form.Text className="text-warning">Ingrese una contraseña de 6 dígitos o más.</Form.Text> }
					</Form.Group>
					<Form.Group>
						<Form.Label>Confirmar contraseña <span className="text-danger">*</span></Form.Label>
						<Form.Control {...register("password_confirmation", {required:true})} placeholder="Ingrese nuevamente la contraseña" type="password" />
						{ errors.password_confirmation && <Form.Text className="text-warning">Las contraseñas no coinciden.</Form.Text> }
					</Form.Group>
				</React.Fragment>
			)}
			<Form.Group>
				<Form.Label>Rol y Permisos <span className="text-danger">*</span></Form.Label>
				<Form.Control as="select" {...register('role',{required:true})} defaultValue={user && user.role}>
					<option value="admin">Administrador</option>
					<option value="agent">Agente Dewar</option>
					<option value="inspector">Inspector</option>
					<option value="client">Cliente</option>
				</Form.Control>
			</Form.Group>
		</React.Fragment>
	)
}