import React from 'react'

export default function DocumentEdsSpecs({ branch, data }) {
	return (
		<div>
			{ branch.instalation_type_name.includes('Estación de Servicio') && (
				<table className='full-width border text-center table-small mb-2'>
					<tbody>
						<tr>
							<td colSpan={8} className='bold bg-light'>Cantidades Específicas (Totales)</td>
						</tr>
						<tr>
							<td className='bold border bg-light'>Tanques</td>
							<td className='border'>{data.eds_stats.total_tanks}</td>
							<td className='bold border bg-light'>Mangueras</td>
							<td className='border'>{data.eds_stats.total_mangueras}</td>
							<td className='bold border bg-light'>Islas</td>
							<td className='border'>
								{(data.service_veredict.service_report.service_review.island_specs && data.service_veredict.service_report.service_review.island_specs.length > 0) ? data.service_veredict.service_report.service_review.island_specs[0].island_number : 0}
							</td>
							<td className='bold border bg-light'>Equipos</td>
							<td className='border'>{data.eds_stats.total_equipos}</td>
						</tr>
					</tbody>
				</table>
			)}
		</div>
	)
}