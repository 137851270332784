import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Tooltip, Divider, message, Popconfirm, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Col, Row } from 'reactstrap'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

import { deleteInquiry } from '../services'
import { handleError } from '../../../helpers'

export default function InquiriesTable({ inquiries, reload, pagination, setPagination }) {
	const currentUser = useSelector(state => state.auth.user)

	const handleDelete = id => {
		deleteInquiry(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'ID',
			dataIndex: 'sku',
			render: (t, r) => <Link to={`/inquiries/preview/${r.id}`}>{t}</Link>
		},
		{
			title: 'Fecha Solicitud',
			dataIndex: 'sent_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Empresa',
			dataIndex: 'branch_spec',
			render: t => t.branch.company_name
		},
		{
			title: 'Tipo',
			dataIndex: 'branch_spec',
			render: t => t.branch.instalation_type_name
		},
		{
			title: 'Instalación',
			dataIndex: 'branch_spec',
			render: t => t.branch.name
		},
		{
			title: 'Fecha Elaboración',
			dataIndex: 'created_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Propuesta',
			dataIndex: 'proposals',
			render: (t, r) => currentUser.role === 'admin' ? (
				<React.Fragment>
					{ t.map(proposal => (
						<React.Fragment key={proposal.id}>
							<Link to={`/proposals/preview/${proposal.id}`} >{proposal.sku}</Link>
							<Divider type="vertical" />
						</React.Fragment>
					)) }
					<Link to={`/proposals/wizard/${r.id}`}><em>Crear propuesta</em></Link>
				</React.Fragment>
			) : (
				<em className='hint-text'>Acceso restringido</em>
			),
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Ver Solicitud">
						<Link to={`/inquiries/preview/${r.id}`}>
							<FontAwesomeIcon className="text-link" icon={faSearch} />
						</Link>
					</Tooltip>
					{ currentUser.role === 'admin' && r.proposals.length === 0 && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm 
								title="¿Desea eliminar la solicitud?"
								okText="Eliminar"
								cancelText="Cancelar"
								onConfirm={() => handleDelete(r.id)}
								okButtonProps={{ danger:true }}
							>
								<FontAwesomeIcon className="text-link" icon={faTrash} />
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRow = r => (
		<React.Fragment>
			<Row>
				<Col>
					<p className='mb-10'><strong>Email:</strong> {r.branch_spec.branch.email ? r.branch_spec.branch.email : <em className='hint-text'>Sin email registrado</em>}</p>
					<p className='mb-10'><strong>Celular:</strong> {r.branch_spec.branch.mobile}</p>
					<p className='mb-10'><strong>Fijo:</strong> {r.branch_spec.branch.phone ? r.branch_spec.branch.phone : <em className='hint-text'>Sin teléfono fijo registrado</em>}</p>
				</Col>
				<Col>
					<p className='mb-10'><strong>Bandera:</strong> {r.branch_spec.branch.flag}</p>
					<p className='mb-10'><strong>SICOM:</strong> {r.branch_spec.branch.sicom_code}</p>
					<p className='mb-10'><strong>Constructor:</strong> {r.branch_spec.branch.constructor_name ? r.branch_spec.branch.constructor_name : <em className='hint-text'>Sin nombre de constructor registrado</em>}</p>
					<p className='mb-0'><strong>Tipo Instalación:</strong> {r.branch_spec.branch.instalation_type_name}</p>
				</Col>
			</Row>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				rowKey={r => r.id}
				dataSource={inquiries}
				loading={!inquiries}
				columns={columns}
				expandable={{ expandedRowRender: expandedRow }}
				size="small"
				pagination={false}
				className="mb-15"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}