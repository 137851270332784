import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Tooltip, Divider, message, Popconfirm, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCashRegister, faCheckDouble, faInbox, faMoneyBill, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Badge, Col, Row } from 'reactstrap'
import moment from 'moment'

import ProposalPreInvoicesModal from '../../PreInvoices/ProposalPreInvoicesModal'
import ProposalAcceptanceModal from './ProposalAcceptanceModal'
import EmailLogsModal from '../../EmailLogs/EmailLogsModal'
import ProposalPaymentsModal from '../../PaymentReceipts/ProposalPaymentsModal'
import Pagination from '../../../components/Pagination'

import { deleteProposal } from '../services'
import { handleError } from '../../../helpers'

export default function ProposalsTable({ proposals, reload, pagination, setPagination }) {
	const currentUser = useSelector(state => state.auth.user)
	const [preInvoicesModal, setPreInvoicesModal] = useState(null)
	const [acceptanceModal, setAcceptanceModal] = useState(null)
	const [paymentReceiptsModal, setPaymentReceiptsModal] = useState(null)
	const [emailLogsModal, setEmailLogsModal] = useState(null)

	const handleDelete = id => {
		deleteProposal(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}
	
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			render: (t, r) => <Link to={`/proposals/preview/${r.id}`}>{r.sku}</Link>
		},
		{
			title: 'Elaboración',
			dataIndex: 'elaborated_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Empresa',
			dataIndex: 'inquiry',
			render: t => t.branch_spec.branch.company_name
		},
		{
			title: 'Instalación',
			dataIndex: 'inquiry',
			render: t => t.branch_spec.branch.name
		},
		{
			title: 'Tipo Instalación',
			dataIndex: 'inquiry',
			render: t => t.branch_spec.branch.instalation_type_name
		},
		{
			title: 'Fecha Envío',
			dataIndex: 'created_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Cta de Cobro',
			dataIndex: 'pre_invoices',
			render: t => t.length > 0 ? (
				<Badge color="success">
					{t.length} Enviada{t.length > 1 && 's'}
				</Badge>
			) : (
				<Badge color='danger'>No enviada</Badge>
			)
		},
		{
			title: 'Estado',
			dataIndex: 'accepted_at',
			render: (t,r) => {
				return <Badge color={r.status.color}>{r.status.label}</Badge>
			}
		},
		{
			title: 'Acciones',
			width: '190px',
			render: (t, record) => (
				<React.Fragment>
					<Tooltip title="Ver Propuesta">
						<Link to={`/proposals/preview/${record.id}`}>
							<FontAwesomeIcon className="text-link" icon={faSearch} />
						</Link>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Cuentas de Cobro">
						<div className='inline' onClick={() => setPreInvoicesModal(record.id)}>
							<FontAwesomeIcon className="text-link" icon={faMoneyBill} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Aceptación de Propuesta">
						<div className='inline' onClick={() => setAcceptanceModal(record)}>
							<FontAwesomeIcon className='text-link' icon={faCheckDouble} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Soportes de Pagos">
						<div className='inline' onClick={() => setPaymentReceiptsModal(record.id)}>
							<FontAwesomeIcon className="text-link" icon={faCashRegister} />
						</div>
					</Tooltip>
					<Divider type="vertical" />
					<Tooltip title="Correos Enviados">
						<div className='inline' onClick={() => setEmailLogsModal(record.id)}>
							<FontAwesomeIcon className="text-link" icon={faInbox} />
						</div>
					</Tooltip>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Eliminar Propuesta">
								<Popconfirm 
									title="¿Desea eliminar propuesta?"
									okText="Eliminar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(record.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faTrash} />{" "}
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRow = r => (
		<React.Fragment>
			<Row>
				<Col>
					<p className='mb-10'><strong>Email:</strong> {r.inquiry.branch_spec.branch.email ? r.inquiry.branch_spec.branch.email : <em className='hint-text'>Sin email registrado</em>}</p>
					<p className='mb-10'><strong>Celular:</strong> {r.inquiry.branch_spec.branch.mobile}</p>
					<p className='mb-10'><strong>Fijo:</strong> {r.inquiry.branch_spec.branch.phone ? r.inquiry.branch_spec.branch.phone : <em className='hint-text'>Sin teléfono fijo registrado</em>}</p>
				</Col>
				<Col>
					<p className='mb-10'><strong>Bandera:</strong> {r.inquiry.branch_spec.branch.flag}</p>
					<p className='mb-10'><strong>SICOM:</strong> {r.inquiry.branch_spec.branch.sicom_code}</p>
					<p className='mb-0'><strong>Constructor:</strong> {r.inquiry.branch_spec.branch.constructor_name ? r.inquiry.branch_spec.branch.constructor_name : <em className='hint-text'>Sin nombre de constructor registrado</em>}</p>
				</Col>
			</Row>
		</React.Fragment>
	)
	
	return (
		<React.Fragment>
			<Table 
				rowKey={r => r.id}
				dataSource={proposals}
				loading={!proposals}
				columns={columns}
				expandable={{ expandedRowRender: expandedRow }}
				size="small"
				pagination={false}
				className="mb-15"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
			{ preInvoicesModal && (
				<ProposalPreInvoicesModal 
					visible
					onClose={() => setPreInvoicesModal(null)}
					proposalId={preInvoicesModal}
					reload={reload}
				/>
			)}
			{ acceptanceModal && (
				<ProposalAcceptanceModal 
					visible
					onClose={() => setAcceptanceModal(null)}
					proposal={acceptanceModal}
					reload={() => {
						setAcceptanceModal(null)
						reload()
					}}
				/>
			)}
			{ paymentReceiptsModal && (
				<ProposalPaymentsModal 
					visible
					onClose={() => setPaymentReceiptsModal(null)}
					proposalId={paymentReceiptsModal}
				/>
			)}
			{ emailLogsModal && (
				<EmailLogsModal 
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="proposal"
					modelId={emailLogsModal}
				/>
			)}
		</React.Fragment>
	)
}