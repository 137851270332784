import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Empty, Descriptions, Switch, Divider } from 'antd'
import moment from 'moment'

import NewNonCompliantReportModal from './partials/NewNonCompliantReportModal'
import VerificationListTasksTable from '../VerificationLists/partials/VerificationListTasksTable'
import { getVerificationTasks } from '../VerificationTasks/services'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'

import SendNotificationButton from '../../components/SendNotificationButton'

import { notifyNonCompliantReport } from './services'
import { handleError, parsePagination } from '../../helpers'
import TasksTicketsModal from './partials/tickets/TasksTicketsModal'
import NewInspectorRequestModal from './partials/tickets/NewInspectorRequestModal'

const { Item } = Descriptions

export default function NonCompliantReportTab({ service, reload }) {
	const [verificationTasks, setVerificationTasks] = useState(null)
	const [onlyRequired, setOnlyRequired] = useState(false)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showTasksTicketsModal, setShowTasksTicketsModal] = useState(false)
	const [showInspectorReqModal, setShowInspectorReqModal] = useState(false)
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const [emailLogsModal, setEmailLogsModal] = useState(null)

	const serviceInspector = service.service_inspector || null
	const servicePlan = serviceInspector ? serviceInspector.service_plan : null
	const serviceReview = servicePlan ? servicePlan.service_review : null
	const nonCompliantReport = serviceReview ? serviceReview.non_compliant_report : null
	const verificationList = service.verification_list

	useEffect(() => {
		!verificationTasks && verificationList && getVerificationTasks({
			...pagination,
			'filter[verification_list_id]': verificationList.id,
			'filter[only_unresolved]': 1,
			'filter[is_require]': onlyRequired ? 1 : null,
			sort: 'sku',
		})
			.then(res => {
				setVerificationTasks(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [ verificationTasks, verificationList, onlyRequired, pagination ])

	const updateTasks = () => {
		getVerificationTasks({
			page: pagination.current_page,
			per_page: pagination.per_page,
			'filter[verification_list_id]': verificationList.id,
			'filter[only_unresolved]': 1,
			'filter[is_require]': onlyRequired ? 1 : null,
			sort: 'sku',
		})
			.then(res => setVerificationTasks(res.data.data))
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			{ nonCompliantReport ? (
				<React.Fragment>
					<Descriptions bordered size="small" className='mt-15' column={2}>
						<Item label="Versión">{nonCompliantReport.version}</Item>
						<Item label="Fecha Vigencia">
							{moment(nonCompliantReport.expire_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
					</Descriptions>
					<p className='bold mt-15'>TAREAS REGISTRADAS NO CONFORMES</p>
					<div className='mb-10 float-right'>
						<Switch 
							checked={onlyRequired} 
							onChange={()=>{
								setOnlyRequired(!onlyRequired)
								setVerificationTasks(null)
							}} 
						/>
						<span className='ml-5'>Mostrar solo requeridas</span>
					</div>
					<VerificationListTasksTable
						verificationList={verificationList}
						verificationTasks={verificationTasks}
						reloadTasks={()=>setVerificationTasks(null)}
						updateTasks={updateTasks}
						editable={service.initial_service_id ? true : false}
						isRetry={service.initial_service_id ? true : false}
						pagination={pagination}
						setPagination={setPagination}
					/>
					<div className='mt-15'>
						<Link to={`/non_compliant_reports/preview/${verificationList.id}`}>
							<Button color="primary" outline>Formato Reporte De No Conformidades</Button>
						</Link>
						<Divider type="vertical" />
						<SendNotificationButton notifyService={() => notifyNonCompliantReport(nonCompliantReport.id)} />
						<Divider type="vertical" />
						<Button color="primary" outline onClick={() => setEmailLogsModal(nonCompliantReport.id)}>
							Correos Enviados
						</Button>
					</div>
					<div className='mt-10'>
						<Button color="primary" outline onClick={() => setShowTasksTicketsModal(true)}>
							Novedades Reportadas
						</Button>
						{ !service.initial_service_id && (
							<React.Fragment>
								<Divider type="vertical" />
								<Button color="primary" outline onClick={() => setShowInspectorReqModal(true)}>
									Solicitar Cierre a Programación Dewar
								</Button>
							</React.Fragment>
						)}
					</div>
				</React.Fragment>
			) : (
				<Empty description="No se tiene un reporte de no conformidades aún" className='pt-50'>
					<Button color="primary" onClick={() => setShowNewModal(true)}>
						Generar Reporte de No Conformidades
					</Button>
				</Empty>
			)}
			{ showNewModal && (
				<NewNonCompliantReportModal 
					visible
					onClose={() => setShowNewModal(false)}
					serviceReviewId={service.service_inspector.service_plan.service_review.id}
					reload={reload}
				/>
			)}
			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="non_compliant_report"
					modelId={emailLogsModal}
				/>
			)}
			{ showTasksTicketsModal && (
				<TasksTicketsModal 
					visible
					onClose={() => setShowTasksTicketsModal(false)}
					verificationList={service.verification_list}
				/>
			)}
			{ showInspectorReqModal && (
				<NewInspectorRequestModal 
					visible
					onClose={() => setShowInspectorReqModal(false)}
					service={service}
				/>
			)}
		</React.Fragment>
	)
}