import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Row, Col, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

import { storeNonCompliantReport } from '../services'
import { handleError } from '../../../helpers'

export default function NewNonCompliantReportModal({ visible, onClose, serviceReviewId, reload }) {
	const [loading, setLoading] = useState(false)
	const { register, handleSubmit, formState: { errors }, setValue } = useForm()

	const onSubmit = values => {
		setLoading(true)
		storeNonCompliantReport({ service_review_id: serviceReviewId, ...values })
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Generar Reporte de No Conformidades</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
								<FormatVersionSelect 
									keyword="nonCompliantReport"
									setValue={setValue}
								/>
								{ errors.version && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
								<Form.Control {...register('expire_at')} disabled />
							</Form.Group>
						</Row>
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Generar Reporte
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}