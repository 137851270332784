import React from 'react'
import styled from 'styled-components'

import ProposalDocument from './ProposalDocument'
import RecertificationProposalDocument from './RecertificationProposalDocument'

export default function ProposalFormPreview({ form, inquiry, isRecertification }) {

	const data = {
		...form,
		employee_name: inquiry.employee.name,
		employee_position: inquiry.employee.position,
		branch_name: inquiry.branch_spec.branch.name,
		branch_address: inquiry.branch_spec.branch.address_line,
		branch_state: inquiry.branch_spec.branch.address_state,
		branch_municipio: inquiry.branch_spec.branch.address_city,
		branch_type_name: inquiry.branch_spec.branch.instalation_type.name,
		branch_type_criteria: inquiry.branch_spec.branch.instalation_type.criteria,
		total: parseInt(form.service_value) + parseInt(form.trip_expenses_value),
		inquiry
	}

	return (
		<React.Fragment>
			<Container>
				{ !isRecertification ? (
					<ProposalDocument data={data} />
				) : (
					<RecertificationProposalDocument data={data} />
				)}
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	max-height: 85vh;
	overflow-y: scroll;
	background-color: #f8f8f8;
	padding: 10px;
	overflow: -moz-scrollbars-vertical; 
`