import React from 'react'
import styled from 'styled-components'
import { Descriptions, Table } from 'antd'
import moment from 'moment'

import DocumentHeader from '../../../components/DocumentHeader'

const { Item } = Descriptions

export default function ServicePlanDocument({ data }) {
	return (
		<Document>
			<DocumentHeader 
				title="PLAN DE INSPECCIÓN"
				version={data.version}
				expireAt={data.expire_at}
			/>
			<Descriptions column={2} bordered size='small'>
				<Item label="Nombre de la Instalación" span={2}>
					{data.service_inspector.service.proposal.inquiry.branch_spec.branch.name}
				</Item>
				<Item label="Representante Legal:" span={2}>
					{data.service_inspector.service.proposal.inquiry.branch_spec.branch.company.legal_owner_name}
				</Item>
				<Item label="Nombre de quien atenderá la Inspección">
					{data.service_inspector.service.proposal.inquiry.employee.name}
				</Item>
				<Item label="Cargo">
					{data.service_inspector.service.proposal.inquiry.employee.position}
				</Item>
				<Item label="Correo electrónico" span={2}>
					{data.service_inspector.service.proposal.inquiry.employee.email}
				</Item>
				<Item label="Dirección" span={2}>
					{data.service_inspector.service.proposal.inquiry.branch_spec.branch.address_line} - {data.service_inspector.service.proposal.inquiry.branch_spec.branch.address_state}, {data.service_inspector.service.proposal.inquiry.branch_spec.branch.address_city}
				</Item>
				<Item label="Teléfono">
					{data.service_inspector.service.proposal.inquiry.branch_spec.branch.mobile}
				</Item>
				<Item label="Celular">
					{data.service_inspector.service.proposal.inquiry.branch_spec.branch.phone}
				</Item>
			</Descriptions>
			<Descriptions column={2} bordered size='small' className='mt-10 mb-10'>
				<Item label="Tipo de Inspección:" span={2}>
					{data.service_inspector.service.initial_service_id ? 'Cierre' : 'Inicial'}
				</Item>
				<Item label="Criterio:" span={2}>
					{data.service_inspector.service.criteria}
				</Item>
			</Descriptions>
			<h6>INSPECTOR ASIGNADO</h6>
			<Descriptions column={2} bordered size='small'>
				<Item span={2} label="Inspector Asignado">{data.service_inspector.user.name}</Item>
				<Item label="Correo Electrónico">{data.service_inspector.user.email}</Item>
				<Item label="Celular">{data.service_inspector.user.mobile}</Item>
			</Descriptions>
			<div className='details_container mt-10'>
				<p className='mb-0'>El inspector asignado es el Representante de DEWAR para la evaluación de la conformidad y es el punto de contacto con el cliente para el recibo y verificación de las no conformidades, cuando aplique. Las evidencias deben ser enviadas al correo del inspector quien confirmará el recibo y aceptación de las mismas. Se realizará una revisión del proceso y toma de decisión la cual está bajo la responsabilidad del Comité Técnico quien comunicará al CLIENTE la decisión en los tiempos establecidos por el procedimiento. Documentos de interés como el Reglamento de Inspección y Apelación quejas y/o reclamos, se encuentran disponibles bajo solicitud.</p>
			</div>
			<Descriptions column={2} bordered size='small'>
				<Item span={2} label="Fecha de la inspección *">{moment(data.inspected_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
				<Item span={2} label="Lugar:">{data.location}</Item>
			</Descriptions>
			<Table 
				dataSource={data.plan_tasks}
				columns={[
					{
						title: 'Hora',
						dataIndex: 'time',
						width: '120px',
						render: t => moment(`01-01-2022 ${t}`).format('hh:mm A')
					},
					{
						title: 'Requisito / Actividad por evaluar',
						dataIndex: 'name'
					},
				]}
				className='mt-10 mb-20'
				size='small'
				pagination={false}
			/>
			<h6>OBSERVACIONES</h6>
			<div className='details_container'>{data.observations}</div>
			<Descriptions bordered size='small'>
				<Item label="Fecha envío del plan">{moment(data.sent_at).format('DD-MMM-YYYY').toUpperCase()}</Item>
			</Descriptions>
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px 30px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
`