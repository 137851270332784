import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../components/DocumentHeader'
import logo from '../../../assets/images/logo_dewar_onac.png'

export default function ServiceVeredictDocument({ data, register, editable }) {
	const proposal = data.service_report.service_review.service_plan.service_inspector.service.proposal
	const branch = proposal.inquiry.branch_spec.branch

	return (
		<Document>
			<DocumentHeader 
				title="REVISIÓN Y DECISIÓN"
				version={data.version}
				expireAt={data.expire_at}
				logoSrc={logo}
			/>
			<p className='section_title'>1. CONFORMACIÓN COMITÉ TÉCNICO</p>
			<table className='table border'>
				<tbody>
					<tr>
						<td className='bg-light bold'>Inspector(a) que presenta el proceso:</td>
						<td className='px-20'>{data.service_report.service_review.service_plan.service_inspector.user.name}</td>
						<td className='bg-light bold'>Consecutivo Comité Técnico:</td>
						<td className='px-20'>{data.service_report.committee_sku_formated}</td>
					</tr>
					<tr>
						<td className='bg-light bold'>Director (a) Técnico (a) miembro Comité Técnico:</td>
						<td className='px-20'>{data.service_report.director_name}</td>
						<td className='bg-light bold'>Fecha del Comité Técnico:</td>
						<td className='px-20'>{moment(data.service_report.committee_at).format('DD-MMM-YYYY').toUpperCase()}</td>
					</tr>
					<tr>
						<td className='bg-light' colSpan={4}>Si para el Presente Comité se da la participación de un inspector adicional convocado se incluye en las observaciones del punto 5 con su correspondiente anexo.</td>
					</tr>
				</tbody>
			</table>
			<p className='section_title'>2. INFORMACIÓN DE LA INSTALACIÓN</p>
			<table className='table border'>
				<tbody>
					<tr>
						<td className='bg-light bold'>Nombre de Instalación:</td>
						<td colSpan={4}>{branch.name}</td>
					</tr>
					<tr>
						<td className='bg-light bold'>Tipo de Instalación:</td>
						<td colSpan={2}>{branch.instalation_type_name}</td>
						<td className='bg-light bold'>Código del Proceso:</td>
						<td className='px-20'>{proposal.sku}</td>
					</tr>
					<tr>
						<td className='bg-light bold'>Proceso de Inspección*:</td>
						<td className='bg-light bold'>Inicial</td>
						<td className='px-20'>{data.initial_inspection_at ? moment(data.initial_inspection_at).format('DD-MMM-YYYY').toUpperCase() : ''}</td>
						<td className='bg-light bold'>Cierre</td>
						<td className='px-20'>{ data.retry_inspection_at ? moment(data.retry_inspection_at).format('DD-MMM-YYYY').toUpperCase() : 'NA' }</td>
					</tr>
				</tbody>
			</table>
			<small>*Se pueden marcar las dos opciones cuando se da proceso tiene inspección inicial y cierre en el mismo proceso.</small>
			<p className='section_title'>
				3. DECLARACIÓN DE IMPARCIALIDAD, INDEPENDENCIA, CONFIDENCIALIDAD Y EXONERACIÓN DE CONFLICTO DE INTERÉS
			</p>
			<p>En calidad miembro del Comité Técnico, declaro que en un lapso no inferior a los (2) años previos a la realización de la presente revisión, NO he tenido relación comercial y/o he realizado actividades de:</p>
			<ul>
				<li>Diseño o suministro de productos a la instalación a verificar</li>
				<li>Aconsejar o brindar servicios de consultoría a los solicitantes, en aspectos relacionados con la inspección solicitada</li>
				<li>Suministrar cualquier otro producto o servicio que pueda comprometer la confidencialidad, objetividad o imparcialidad de su proceso de inspección y decisiones</li>
			</ul>
			<p>También declaro que en la participación del presenté Comité manejaré la información observada en la instalación de manera confidencial con lo cual me comprometo a seguir los lineamientos establecidos por el Organismo de Inspección DEWAR S.A.S., para el desarrollo del Comité técnico tales como procedimientos, guías y formularios con el fin de no poner en riesgo el nombre de la firma que represento.</p>
			<p>Así mismo, declaró no presentar conflicto de interés con las instalaciones relacionadas para las cuales he sido convocado como miembro del Comité técnico, y me comprometo a su realización bajo el cumplimiento de los procedimientos del DEWAR como organismo de inspección, y el Reglamento de inspección.</p>
			<p>Por último, declaro revisar y decidir fuera de presiones de alguna índole, y las decisiones tomadas corresponde netamente a mi competencia profesional guiadas por el cumplimiento del Reglamento Técnico vigente objeto del proceso de inspección.</p>
			<p className='section_title'>4. REGISTROS DEL PROCESO A REVISAR Y DECIDIR</p>
			<p>Todos los registros del proceso de inspección para la revisión y decisión se encuentran en el expediente del proceso del Programa de SIDIN – Sistema de información DEWAR organismo de inspección. Cuyos participantes del Comité tienen el ingreso autorizado a esta plataforma.</p>
			{/* <table className='table border'>
				<thead className='bg-light'>
					<tr>
						<th>Registro</th>
						<th colSpan={2}>Existencia Si/No/N/A</th>
					</tr>
					<tr>
						<th></th>
						<th>Inspección Inicial</th>
						<th>Inspección Cierre</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className='text-left'>a. Acta de Inspección</td>
						<td className='border'>
							<select {...register('acta_inspeccion_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('acta_inspeccion_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>b. Lista de verificación</td>
						<td className='border' colSpan={2}>
							<select {...register('lista_verificacion')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>c. Reporte de No Conformidades</td>
						<td className='border'>
							<select {...register('no_conformidades_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('no_conformidades_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>d. Registro fotográfico</td>
						<td className='border'>
							<select {...register('fotos_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('fotos_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>e. Registro documental</td>
						<td className='border'>
							<select {...register('documental_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('documental_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>f. Ratificación imparcialidad - Si aplica </td>
						<td className='border'>
							<select {...register('imparcialidad_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('imparcialidad_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>g. Informe de Inspección</td>
						<td className='border' colSpan={2}>
							<select {...register('informe_inspeccion')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
				</tbody>
			</table> */}
			<p className='section_title'>5. REVISIÓN Y OBSERVACIONES DEL COMITÉ TÉCNICO</p>
			<table className='table text-center border'>
				{/* <thead className='bg-light'> */}
					{/* <tr>
						<th>Observaciones del Comité Técnico</th>
					</tr> */}
				{/* </thead> */}
				<tbody>
					{/* <tr>
						<td className='bg-light bold'>Observaciones Inspector (a) como miembro del Comité Técnico:</td>
					</tr>
					<tr>
						<td style={{ padding: '10px', minHeight: '100px' }} className='text-left'>
							{data.service_report.inspector_observations}
						</td>
					</tr> */}
					<tr>
						<td className='bg-light bold'>Observaciones Director (a) Técnico (a) como miembro del Comité Técnico:</td>
					</tr>
					<tr>
						<td style={{ padding: '10px', minHeight: '100px' }} className='text-left'>
							{data.service_report.director_observations}
						</td>
					</tr>
				</tbody>
			</table>
			<p className='section_title'>6. DECISIÓN COMITÉ TÉCNICO</p>
			<table className='table border mb-20'>
				<tbody>
					<tr className='bg-light text-center bold'>
						<td>Decisión Inspector (a)</td>
						<td>Decisión Director (a) Técnico (a):</td>
					</tr>
					<tr>
						<td className='text-center'>
							<table className='full-width mt-10 mb-10 border'>
								<tbody>
									<tr>
										<td className='bg-light bold'>Otorgar</td>
										<td className='px-20'>
											{ data.service_report.service_review.is_compliant === 1 && 'X' }
										</td>
										<td className='bg-light bold'>No Otorgar</td>
										<td className='px-20'>
											{ data.service_report.service_review.is_compliant === 0 && 'X' }
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td className='text-center'>
							<table className='full-width my-10 border'>
								<tbody>
									<tr>
										<td className='bg-light bold'>Otorgar</td>
										<td className='px-20'>
											{ data.service_report.is_director_compliant === 1 && 'X' }
										</td>
										<td className='bg-light bold'>No Otorgar</td>
										<td className='px-20'>
											{ data.service_report.is_director_compliant === 0 && 'X' }
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td colSpan={2} className='bg-light'>Si la decisión es no otorgar por favor exponer las razones correspondientes / Si existe participación de un inspector convocado como experto dejar anotado su posición y decisión frente al proceso:</td>
					</tr>
					<tr>
						<td colSpan={2} style={{ padding: '40px', minHeight: '100px' }}>
							{data.service_report.committee_observations ? data.service_report.committee_observations : <em>Sin observaciones</em>}
						</td>
					</tr>
				</tbody>
			</table>
			<table className='table border'>
				<tbody className='text-center'>
					<tr>
						<td className='bg-light bold'>Decisión del Comité Técnico:</td>
					</tr>
					<tr>
						<td>Teniendo en cuenta lo anterior el Comité Técnico decide que la Instalación objeto del proceso de inspección es:</td>
					</tr>
					<tr>
						<td className='text-center'>
							<table className='full-width my-10 border'>
								<tbody>
									<tr>
										<td className='bg-light bold'>Conforme</td>
										<td className='px-20'>
											{ data.service_report.is_committee_compliant === 1 && 'X' }
										</td>
										<td className='bg-light bold'>No Conforme</td>
										<td className='px-20'>
											{ data.service_report.is_committee_compliant === 0 && 'X' }
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr>
						<td>Por lo tanto, el Comité de técnico en pleno ejercicio de las funciones establecidas en el procedimiento de revisión y decisión y declarando, no haber recibido ningún tipo de presión y haber sido: imparcial e independiente en la toma de decisión, decide sobre el certificado de inspección a la Instalación de referencia:</td>
					</tr>
					<tr>
						<td className='text-center'>
							<table className='full-width my-10 border'>
								<tbody>
									<tr>
										<td className='bg-light bold'>Otorgar</td>
										<td className='px-20'>
											{ data.service_report.is_committee_compliant === 1 && 'X' }
										</td>
										<td className='bg-light bold'>No Otorgar</td>
										<td className='px-20'>
											{ data.service_report.is_committee_compliant === 0 && 'X' }
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			{/* <p><strong>Firmas Miembros del Comité Técnico:</strong></p>
			<table className='table border full-width'>
				<tbody className='text-center'>
					<tr>
						<td className='py-10 px-20 border'></td>
						<td className='py-10 px-20'></td>
					</tr>
					<tr>
						<td className='py-10 px-20 border'></td>
						<td className='py-10 px-20'></td>
					</tr>
				</tbody>
			</table>
			<table className='table border full-width'>
				<tbody className='text-center'>
					<tr>
						<td className='py-10 px-20 border'></td>
						<td className='py-10 px-20 border'></td>
					</tr>
					<tr>
						<td className='py-10 px-20 border'></td>
						<td className='py-10 px-20'></td>
					</tr>
				</tbody>
			</table>
			<table className='table border full-width'>
				<tbody className='text-center'>
					<tr>
						<td className='bg-light bold border'>Inspector (a) Miembro (a)</td>
						<td className='bg-light bold'>Director (a) Técnico (a)</td>
					</tr>
					<tr>
						<td className='border' style={{ height: '70px' }}></td>
						<td style={{ height: '70px' }}></td>
					</tr>
				</tbody>
			</table> */}
			{/* <p className='section_title text-center'>ANEXO 1. EXPEDIENTE DEL PROCESO</p>
			<p className='text-center mt-20 mb-30'>La Dirección Técnica evidencia la existencia de los siguientes registros en el Expediente del proceso:</p>
			<table className='table table-sm text-center border'>
				<thead className='bg-light'>
					<tr>
						<th>Número de Expediente</th>
						<th colSpan={2}></th>
					</tr>
					<tr>
						<th>Registro</th>
						<th className='border' colSpan={2}>Existencia Si/No/N/A</th>
					</tr>
					<tr>
						<th></th>
						<th className='border'>Inspección Inicial</th>
						<th className='border'>Inspección Cierre</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td className='text-left'>1. Solicitud de servicio.</td>
						<td className='border' colSpan={2}>
							<select {...register('anexo1_solicitud_servicio')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>2. Propuesta comercial.</td>
						<td className='border' colSpan={2}>
							<select {...register('anexo1_propuesta_comercial')} className='form-control' disabled={!editable}>
									<option>:: Eliga una opción ::</option>
									<option>Si</option>
									<option>No</option>
									<option>N/A</option>
								</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>3. Carta de aceptación/Orden de compra</td>
						<td className='border' colSpan={2}>
							<select {...register('anexo1_carta_aceptacion')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>4. Asignación de Inspección</td>
						<td className='border'>
							<select {...register('anexo1_asignacion_inspeccion_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('anexo1_asignacion_inspeccion_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>5. Notificación de Inspección</td>
						<td className='border'>
							<select {...register('anexo1_notificacion_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('anexo1_notificacion_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>6. Plan de Inspección</td>
						<td className='border'>
							<select {...register('anexo1_plan_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('anexo1_plan_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>7. Acta de Inspección</td>
						<td className='border'>
							<select {...register('anexo1_acta_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('anexo1_acta_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>8. Lista de verificación</td>
						<td className='border' colSpan={2}>
							<select {...register('anexo1_lista_verificacion')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>9. Reporte de No Conformidades</td>
						<td className='border'>
							<select {...register('anexo1_no_conformidades_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('anexo1_no_conformidades_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>10. Registro fotográfico</td>
						<td className='border'>
							<select {...register('anexo1_fotos_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('anexo1_fotos_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>11. Registro documental</td>
						<td className='border'>
						<select {...register('anexo1_documental_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('anexo1_documental_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>12. Ratificación imparcialidad - Si aplica </td>
						<td className='border'>
							<select {...register('anexo1_imparcialidad_inicial')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
						<td>
							<select {...register('anexo1_imparcialidad_cierre')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>13. Informe de Inspección</td>
						<td className='border' colSpan={2}>
							<select {...register('anexo1_informe')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>14. Se archiva Revisión y Decisión</td>
						<td className='border' colSpan={2}>
							<select {...register('anexo1_revision')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
					<tr>
						<td className='text-left'>15. Se emite Certificado de Inspección/Comunicado de decisión</td>
						<td className='border' colSpan={2}>
							<select {...register('anexo1_certificado')} className='form-control' disabled={!editable}>
								<option>:: Eliga una opción ::</option>
								<option>Si</option>
								<option>No</option>
								<option>N/A</option>
							</select>
						</td>
					</tr>
				</tbody>
			</table> */}
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px 30px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
	.section_title {
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.ant-table-content {
		margin-top: 15px;
	}
`