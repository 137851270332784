import React from 'react'
import styled from 'styled-components'
import moment from 'moment'

import DocumentHeader from '../../../components/DocumentHeader'
import logo from '../../../assets/images/logo_dewar_onac.png'

export default function DecisionNoticeDocument({ data }) {
	const proposal = data.service_veredict.service_report.service_review.service_plan.service_inspector.service.proposal
	const company = proposal.inquiry.branch_spec.branch.company

	return (
		<Document>
			<DocumentHeader 
				title="COMUNICADO DECISIÓN PROCESO DE INSPECCIÓN"
				version={data.version}
				expireAt={data.expire_at}
				logoSrc={logo}
			/>
			<p className='text-right mb-10'>Bogotá D.C., {moment(data.published_at).format('DD MMMM YYYY')}</p>
			<p className='mb-5'>Señores:</p>
			<p className='mb-5'><strong>{company.legal_owner_name}</strong></p>
			<p className='mb-5'><strong>INSTALACIÓN {proposal.inquiry.branch_spec.branch.name}</strong></p>
			<p className='mb-5'>{proposal.inquiry.branch_spec.branch.address_line}</p>
			<p className='mb-20'>{proposal.inquiry.branch_spec.branch.address_city}</p>
			{ (data.service_veredict.service_report.is_committee_compliant && data.service_veredict.certificate) ? (
				<React.Fragment>
					<p>Una vez evaluada la conformidad a través del proceso de inspección de la instalación {proposal.inquiry.branch_spec.branch.name}, el Comité Técnico de DEWAR, como Organismo de inspección decide otorgar el Certificado de inspección a la instalación.</p>
					<p>A través de la presente se le hace entrega del certificado de inspección {data.service_veredict.certificate.sku_formated}  como resultado satisfactorio de la inspección, adelantada por el proceso de certificación que hace parte del acuerdo legalmente ejecutable, es por esto que para mantener el certificado es de obligatorio cumplimiento de las condiciones de la propuesta comercial y el reglamento de inspección previamente aceptado por ustedes.</p>
					<p>La vigencia del certificado es de 2 años a partir de la fecha de emisión, está determinada por el numeral 13.1 de la Resolución 40198 de 2021 o aquella que lo modifique o sustituya.</p>
					<p>La instalación titular del certificado de inspección debe realizar la gestión de radicación del certificado de inspección ante el Ministerio de Minas y Energía y el SICOM, según corresponda.</p>
					<p>Así mismo, si existen modificaciones a la instalación que cambian las condiciones del certificado de inspección, la Instalación debe notificar a DEWAR para renovar el certificado. Les recordamos que en el Reglamento de Inspección podrá consultar las condiciones y requisitos específicos para publicar y mantener el certificado de inspección otorgado.</p>
					<p>Por último, les agradecemos en caso de modificar los datos de contacto que fueron registrados en la solicitud de servicio, se notifique a DEWAR.</p>
				</React.Fragment>
			) : (
				<React.Fragment>
					<p>Una vez evaluada la conformidad a través del proceso de inspección de la instalación {proposal.inquiry.branch_spec.branch.name}, el Comité Técnico de DEWAR, como Organismo de inspección decide no otorgar el Certificado de inspección a la instalación.</p>
					{ data.custom_response ? data.custom_response : (
						<React.Fragment>
							<p>En la propuesta comercial aceptada por ustedes para el desarrollo  del proceso de inspección de la instalación {proposal.inquiry.branch_spec.branch.sku}, está establecido un plazo de noventa (90) días calendario desde la fecha de la inspección inicial para el cierre de las No Conformidades (NC) que se establezcan en el mencionado proceso.</p>
							<p>Con base en lo anterior y dado que para la instalación referida el   plazo establecido llegó a su término, sin tener evidencias de atención a las no conformidades y cumplimiento al reglamento técnico necesario para el cierre del proceso, no es viable otorgar el correspondiente certificado de inspección de acuerdo con la decisión tomada en el Comité Técnico.</p>
						</React.Fragment>
					)}
					<p>En el momento que la instalación considere, puede solicitar a DEWAR una propuesta comercial para realizar una nueva inspección y poder evidenciar el cumplimiento requerido para la declaración de la conformidad. Dicha propuesta se enviará con el costo del servicio, y deberá ser aprobado y cancelado para continuar con el proceso.</p>
					<p>Teniendo en cuenta el Reglamento Inspección frente a las decisiones del Comité Técnico se podrá interponer apelación justificada en el plazo de cinco (05) días hábiles contados desde la fecha de la comunicación de la decisión, conforme con el Procedimiento de Apelación, Quejas y Reclamos. De ser aceptada dicha apelación se levanta la decisión hasta ser presentada nuevamente a consideración del Comité Técnico.</p>
				</React.Fragment>
			)}
			<p className='mb-40'>Ampliaremos la información que sea de su interés </p>
			<p className='mb-40'>Cordialmente,</p>
			<p className='mt-40 mb-5'>{data.service_veredict.service_report.director_name}</p>
			<p className='mb-5'>Directora Técnica.</p>
			<p className='mb-5'>Dewar S.A.S.</p>
			<p className='mt-30 text-center'>Carrera 61 No. 96 A – 23 Oficina 201 Barrio Rincón de los Andes. Bogotá D.C. – Colombia. <br /> Celular  3163702077 organismo@dewar.com.co – www.dewar.com.co</p>
		</Document>
	)
}

const Document = styled.div`
	background-color: #fff;
	padding: 15px 40px;
	margin: auto;
	p {
		font-size: 15px;
	}
	span.variable {
		color: #e78035;
		font-weight: 600;
	}
	h5 {
		font-size: 21px;
	}
	h6 {
		margin-top: 10px;
		font-size: 17px;
	}
	.details_container {
		padding: 15px;
		border: 2px dashed #888;
		margin-bottom: 20px;
	}
	.section_title {
		font-weight: bold;
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.ant-table-content {
		margin-top: 15px;
	}
`