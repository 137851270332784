import React from 'react'
import { Descriptions } from 'antd'

const { Item } = Descriptions

export default function BranchDetails({ branch }) {
	return (
		<React.Fragment>
			<Descriptions bordered size="small" className="mt-10 mb-10">
				<Item label="Nombre de la Instalación">
					{branch && branch.name}
				</Item>
				<Item label="Dirección">
					{branch && branch.address_line}
				</Item>
				<Item label="Departamento">
					{branch && branch.address_state}
				</Item>
				<Item label="Municipio">
				{branch && (branch.address_city ? branch.address_city : <em>Sin registro</em>)}
				</Item>
				<Item label="Correo Electrónico(s)">
					{branch && `${branch.email} ${branch.email_secundary ? branch.email_secundary : ''} ${branch.email_terciary ? branch.email_terciary : ''}`}
				</Item>
				<Item label="Teléfono">
					{branch && (branch.phone ? branch.phone : <em>Sin registro</em>)}
				</Item>
				<Item label="Celular">
					{branch && branch.mobile}
				</Item>
				<Item label="Bandera">
					{branch && branch.flag}
				</Item>
				<Item label="Código SICOM">
					{branch && branch.sicom_code}
				</Item>
				<Item label="Nombre del Constructor">
					{branch && (branch.constructor_name ? branch.constructor_name : <em>Sin registro</em>)}
				</Item>
			</Descriptions>
		</React.Fragment>
	)
}