import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Spinner } from 'reactstrap'
import { Tabs } from 'antd'
import { Redirect } from 'react-router'

import ServiceProfileDetails from './partials/ServiceProfileDetails'
import ServiceProfileTopBar from './partials/ServiceProfileTopBar'

import ServiceInspectorTab from '../ServiceInspectors/ServiceInspectorTab'
import ServiceNotificationTab from '../ServiceNotifications/ServiceNotificationTab'
import ServicePlanTab from '../ServicePlans/ServicePlanTab'
import ServiceReviewTab from '../ServiceReviews/ServiceReviewTab'
import NonCompliantReportTab from '../NonCompliantReports/NonCompliantReportTab'
import VerificationListTab from '../VerificationLists/VerificationListTab'
import ServiceResolutionTab from './ServiceResolutionTab'
import RetryNoticeTab from '../RetryNotices/RetryNoticeTab'
import ServiceEvidenceTab from '../Evidence/ServiceEvidenceTab'

import { getForSyncService } from './services'
import { handleError } from '../../helpers'

const { TabPane } = Tabs

export default function ServiceProfile(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.match.params.id
	const user = useSelector(state => state.auth.user)
	const [service, setService] = useState(null)
	const [currentTab, setCurrentTab] = useState('asignacion')
	const [filters, setFilters] = useState({ onlyUncompliant: false, pendingTasks: { periods: [] }})
	

	useEffect(() => {
		!service && isConnected && getForSyncService(id)
			.then(res => setService(res.data.data))
			.catch(error => handleError(error))
	}, [ service, id, isConnected ])

	if(!isConnected) return <Redirect to={`/offline/services/${id}`} />

	if(!service) return <Spinner />

	const reload = () => setService(null)

	const tabProps = {
		service,
		reload,
		filters,
		setFilters
	}

	return (
		<React.Fragment>
			<ServiceProfileTopBar service={service} reload={reload} setCurrentTab={setCurrentTab} />
			<Card>
				<CardBody>
					<Tabs tabPosition='left' type="card" activeKey={currentTab} onChange={key => setCurrentTab(key)}>
						<TabPane key="asignacion" tab="1. Asignación de Inspección">
							<h6 className='mt-10 mb-0 text-center'>Asignación de Inspección</h6>
							<ServiceInspectorTab {...tabProps} />
						</TabPane>
						<TabPane key="notificacion" 
							tab="2. Notificación de Inspección"
							disabled={!service.service_inspector || !service.service_inspector.accepted_at}
						>
							<h6 className='mt-10 mb-0 text-center'>Notificación de Inspección</h6>
							<ServiceNotificationTab {...tabProps} />
						</TabPane>
						<TabPane key="plan"
							tab="3. Plan de Inspección" 
							disabled={!(service.service_inspector && service.service_inspector.service_notification)}
						>
							<h6 className='mt-10 mb-0 text-center'>Plan de Inspección</h6>
							<ServicePlanTab {...tabProps} />
						</TabPane>
						<TabPane key="acta"
							tab="4. Acta de Inspección" 
							disabled={!(service.service_inspector && service.service_inspector.service_plan)}
						>
							<h6 className='mt-10 mb-0 text-center'>Acta de Inspección</h6>
							<ServiceReviewTab {...tabProps} />
						</TabPane>
						<TabPane key="lista"
							tab="5. Lista de Verificación" 
							disabled={!(service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review)}
						>
							<h6 className='mt-10 mb-0 text-center'>Lista de Verificación</h6>
							<VerificationListTab {...tabProps} />
						</TabPane>
						{ service.type === 'Inicial' ? (
							<TabPane key="evidencias"
								tab="6. Evidencias Relacionadas" 
								disabled={!service.verification_list}
							>
								<h6 className='mt-10 mb-0 text-center'>Evidencias según Lista de Verificación</h6>
								<ServiceEvidenceTab {...tabProps} />
							</TabPane>
						) : (
							<TabPane key="evidencias"
								tab="6. Evidencias No Conformidades" 
								disabled={!service.verification_list}
							>
								<h6 className='mt-10 mb-0 text-center'>Evidencias según Reporte de No Conformidades</h6>
								<ServiceEvidenceTab {...tabProps} onlyNonCompliant={true} />
							</TabPane>
						)}
						<TabPane key="noconformidades"
							tab="7. Reporte No Conformidades" 
							disabled={!(service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review && service.verification_list)}
						>
							<h6 className='mt-10 mb-0 text-center'>Reporte de No Conformidades</h6>
							<NonCompliantReportTab {...tabProps} />
						</TabPane>
						<TabPane key="informe"
							tab="8. Resultado de Inspección" 
							disabled={!(service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review && service.service_inspector.service_plan.service_review.non_compliant_report && service.verification_list)}
						>
							<h6 className='mt-10 mb-0 text-center'>Resultado de Inspección</h6>
							<ServiceResolutionTab {...tabProps} />
						</TabPane>
						{ user.role !== 'inspector' && service.type === 'Inicial' && (
							<TabPane
								key="retrynotice"
								tab="9. Carta Inspección de Cierre"
								disabled={!((service.service_inspector && service.service_inspector.service_plan && service.service_inspector.service_plan.service_review && service.service_inspector.service_plan.service_review.retry_notice) || service.status.includes('_retry_needed'))}
							>
								<h6 className='mt-10 mb-0 text-center'>Comunicado Inspección de Cierre</h6>
								<RetryNoticeTab {...tabProps} />
							</TabPane>
						)}
					</Tabs>
				</CardBody>
			</Card>
			<ServiceProfileDetails 
				service={service} 
				reload={() => setService(null)}
			/>
		</React.Fragment>
	)
}