import React from 'react'
import { useSelector } from 'react-redux'
import { message, Popconfirm, Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOn, faUnlink } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

import { deleteFormatVersion, restoreFormatVersion } from '../services'
import { handleError } from '../../../helpers'

export default function FormatVersionsTable({ formatVersions, reload }) {
	const currentUser = useSelector(state => state.auth.user)

	const handleDelete = id => {
		deleteFormatVersion(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const handleRestore = id => {
		restoreFormatVersion(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	const columns = [
		{
			title: 'Versión',
			dataIndex: 'sku'
		},
		{
			title: 'Formato',
			dataIndex: 'keyword',
			render: t => {
				let words = t.split('(')
				return words[0]
			}
		},
		{
			title: 'Fecha Vigencia',
			dataIndex: 'expire_at',
			render: t => moment(t).format('DD-MMM-YYYY').toUpperCase()
		},
		{
			title: 'Estado',
			dataIndex: 'deleted_at',
			render: t => t ? 'Desactivado' : 'Activo'
		},
		{
			title: 'Acciones',
			render: (t, record) => (
				<React.Fragment>
					{ currentUser.role === 'admin' && (
						<React.Fragment>
							{ !record.deleted_at ? (
								<Popconfirm 
									title="¿Desea desactivar la version?"
									okText="Desactivar"
									cancelText="Cancelar"
									onConfirm={() => handleDelete(record.id)}
									okButtonProps={{ danger:true }}
								>
									<FontAwesomeIcon className="text-link" icon={faUnlink} />{" "}
									<span className='text-link'>Desactivar</span>
								</Popconfirm>
							) : (
								<Popconfirm 
									title="¿Desea activar la version?"
									okText="Activar"
									cancelText="Cancelar"
									onConfirm={() => handleRestore(record.id)}
								>
									<FontAwesomeIcon className="text-link" icon={faToggleOn} />{" "}
									<span className='text-link'>Activar</span>
								</Popconfirm>
							)}
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	return (
		<React.Fragment>
			<Table 
				dataSource={formatVersions}
				columns={columns}
				rowKey={record => record.id}
				size="small"
			/>
		</React.Fragment>
	)
}