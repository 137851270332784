import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import CertificatesTable from './partials/CertificatesTable'

import { getCertificates } from './services'
import { handleError, parsePagination } from '../../helpers'
import InternetRequired from '../Offline/partials/InternetRequired'

export default function CertificatesList() {
	const { isConnected } = useSelector(state => state.offline)
	const [certificates, setCertificates] = useState(null)
	const [filterType, setFilterType] = useState('serviceVeredict.serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company.name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})

	useEffect(() => {
		!certificates && isConnected && getCertificates({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'serviceVeredict.serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company'
		})
			.then(res => {
				setCertificates(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [certificates, filterType, filterValue, isConnected, pagination])

	if(!isConnected) return <InternetRequired />

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Certificados Inspección"
				subtitle="Plataforma DEWAR"
				reload={() => setCertificates(null)}
				options={[
					{ label: 'Buscar por consecutivo del certificado', value: 'sku' },
					{ label: 'Buscar por nombre empresa', value: 'serviceVeredict.serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company.name' },
					{ label: 'Buscar por documento empresa', value: 'serviceVeredict.serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company.document' },
					{ label: 'Buscar por nombre instalación', value: 'serviceVeredict.serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.name' },
					{ label: 'Buscar por tipo de instalación', value: 'serviceVeredict.serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.instalationType.name' },
					{ label: 'Buscar por departamento', value: 'serviceVeredict.serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.address_state' },
				]}
			/>
			<Card>
				<CardBody>
					<CertificatesTable 
						certificates={certificates}
						reload={() => setCertificates(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}