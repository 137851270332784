import React from 'react'
import { Descriptions } from 'antd'

const { Item } = Descriptions

export default function EmployeeDetails({ employee }) {
	return (
		<React.Fragment>
			<Descriptions bordered size="small" className="mt-10 mb-10">
				<Item label="Nombre Completo">
					{employee && employee.name}
				</Item>
				<Item label="Cargo">
					{employee && employee.position}
				</Item>
				<Item label="Correo Electrónico">
					{employee && employee.email}
				</Item>
				<Item label="Teléfono">
					{employee && (employee.phone ? employee.phone : <em>Sin registro</em>)}
				</Item>
				<Item label="Celular">
					{employee && employee.mobile}
				</Item>
			</Descriptions>
		</React.Fragment>
	)
}