import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Spinner } from 'react-bootstrap'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { message } from 'antd'

import ServicePlanForm from './ServicePlanForm'

import { storeServicePlan } from '../services'
import { handleError } from '../../../helpers'

export default function NewServicePlanModal({ visible, onClose, service, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		defaultValues: {
			observations: 'Por favor confirmar recibo de ese Plan y enviar a mi correo electrónico un comunicado oficial de la instalación informando si ha realizado ó no modificaciones o ampliaciones desde la última visita de inspección de la instalación. En caso afirmativo especificar cuales modificaciones o ampliaciones. ¡¡Gracias!!'
		}
	})
	const [loading, setLoading] = useState(false)

	const onSubmit = values => {
		setLoading(true)
		storeServicePlan({ ...values, service_inspector_id: service.service_inspector.id, })
			.then(res => {
				message.success(res.data.message)
				onClose()
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Plan de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<ServicePlanForm 
							register={register} 
							errors={errors}
							setValue={setValue}
						/>
						<Button color="primary" type='submit' className='mt-10' disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Crear Plan de Inspección
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}