import React, { useState, useEffect } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

import { getRequirements } from './services'
import { handleError } from '../../helpers'
import RequirementsTable from './partials/RequirementsTable'
import NewRequirementModal from './partials/NewRequirementModal'

export default function RequirementsModal({ visible, onClose, instalationType }) {
	const [requirements, setRequirements] = useState(null)
	
	useEffect(() => {
		!requirements && getRequirements({ 'filter[instalation_type_id]': instalationType.id })
			.then(res => setRequirements(res.data.data))
			.catch(error => handleError(error))
	}, [requirements, instalationType])

	return (
		<Modal isOpen={visible} size="lg">
			<ModalHeader toggle={onClose}>Documentos requeridos para inspección</ModalHeader>
			<ModalBody>
				<p>Requerimientos para instalación de tipo: {instalationType.name}</p>
				<RequirementsTable 
					requirements={requirements}
					reload={() => setRequirements(null)}
				/>
				<NewRequirementModal 
					reload={() => setRequirements(null)} 
					typeId={instalationType.id}
				/>
			</ModalBody>
		</Modal>
	)
}