import dewarApi, { getDomain } from '../../services/dewarApi'

export const getProposals = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/proposals', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getProposal = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/proposals/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeProposal = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/proposals', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateProposal = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/proposals/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteProposal = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/proposals/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const publicGetProposal = (id, params) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get(`/public/proposals/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchProposals = async (name, token) => (
	fetch( getDomain()+`/proposals?filter[inquiry.branchSpec.branch.company.name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(proposal => ({
				label: proposal.sku + ': ' + proposal.company_name,
				value: proposal.id,
			})),
		)
)

export const searchPendingProposals = async (term, token) => (
	fetch( getDomain()+`/proposals?filter[sku_key]=${term}&filter[pending]=1&per_page=50`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(proposal => ({
				label: proposal.sku + ': ' + proposal.company_name,
				value: proposal.id,
			})),
		)
)

export const notifyProposal = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post(`/proposals/${id}/notify`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)