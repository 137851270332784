import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'

import CertificateDocument from './partials/CertificateDocument'
import InternetRequired from '../Offline/partials/InternetRequired'
import CertificateTopBar from './partials/CertificateTopBar'

import { publicGetCertificate } from './services'
import { handleError } from '../../helpers'

export default function CertificatePreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [certificate, setCertificate] = useState(null)
	const [hideBackground, setHideBackground] = useState(false)
	const [isOfficial, setIsOfficial] = useState(props.isOfficial ? true : false)

	useEffect(() => {
		!certificate && isConnected && publicGetCertificate(id, {
			include: 'serviceVeredict.serviceReport.serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company,serviceVeredict.serviceReport.serviceReview.tankSpecs,serviceVeredict.serviceReport.serviceReview.islandSpecs',
			append: 'initial_inspection_at,retry_inspection_at,tanks_total_capacity,eds_stats'
		})
			.then(res => setCertificate(res.data.data))
			.catch(error => handleError(error))
	}, [ certificate, id, isConnected ])

	if(!isConnected) return <InternetRequired />

	if(!certificate) return <Spinner />
	
	return (
		<React.Fragment>
			<CertificateTopBar 
				certificateSku={certificate.sku_formated}
				isAdmin={props.isAdmin}
				hideBackground={hideBackground}
				setHideBackground={setHideBackground}
				isOfficial={isOfficial}
				setIsOfficial={setIsOfficial}
			/>
			<div className='px-0' id="certificateDiv">
				<CertificateDocument 
					data={certificate}
					filters={{ 
						hideBackground,
						isOfficial
					}}
				/>
			</div>
		</React.Fragment>
	)
}