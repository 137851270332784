import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Card, CardBody } from 'reactstrap'

import ListTopBar from '../../components/ListTopBar'
import ProposalsTable from './partials/ProposalsTable'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getProposals } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function ProposalsList() {
	const { isConnected } = useSelector(state => state.offline)
	const [proposals, setProposals] = useState(null)
	const [filterType, setFilterType] = useState('inquiry.branchSpec.branch.company.name')
	const [filterValue, setFilterValue] = useState('')
	const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
	const history = useHistory()

	useEffect(() => {
		!proposals && isConnected && getProposals({
			...pagination,
			[`filter[${filterType}]`]: filterValue,
			include: 'inquiry,preInvoices,inquiry.branchSpec.branch'
		})
			.then(res => {
				setProposals(res.data.data)
				setPagination(parsePagination(res))
			})
			.catch(error => handleError(error))
	}, [proposals, filterType, filterValue, isConnected, pagination])

	if(!isConnected) return <InternetRequired />

	return (
		<React.Fragment>
			<ListTopBar 
				filterType={filterType}
				setFilterType={setFilterType}
				filterValue={filterValue}
				setFilterValue={setFilterValue}
				title="Propuestas Comerciales"
				subtitle="Plataforma DEWAR"
				reload={() => setProposals(null)}
				options={[
					{ label: 'Buscar por empresa', value: 'inquiry.branchSpec.branch.company.name' },
					{ label: 'Buscar por nombre instalación', value: 'inquiry.branchSpec.branch.name' },
					{ label: 'Buscar por número de consecutivo', value: 'sku_key' },
					{ label: 'Buscar por año de consecutivo (YYYY)', value: 'elaborated_at' },
					{ label: 'Buscar por fecha de elaboración (YYYY-MM-DD)', value: 'elaborated_at' },
				]}
				ctaText="Solicitudes de Servicio"
				handleCtaClick={() => history.push('/inquiries')}
			/>
			<Card>
				<CardBody>
					<ProposalsTable 
						proposals={proposals}
						reload={() => setProposals(null)}
						pagination={pagination}
						setPagination={setPagination}
					/>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}