import dewarApi, { getDomain } from '../../services/dewarApi'

export const getRequirements = params => (
	new Promise((resolve, reject) => {
		dewarApi()
			.get('/requirements', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeRequirement = data => (
	new Promise((resolve, reject) => {
		dewarApi()
			.post('/requirements', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateRequirement = (id, data) => (
	new Promise((resolve, reject) => {
		dewarApi()
			.put(`/requirements/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteRequirement = id => (
	new Promise((resolve, reject) => {
		dewarApi()
			.delete(`/requirements/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const searchRequirements = async (name, token) => (
	fetch( getDomain()+`/requirements?filter[name]=${name}`, {
		headers: {'Authorization': `Bearer ${token}`}
	})
		.then((response) => response.json())
		.then((res) =>
			res.data.map(requirement => ({
				label: requirement.name,
				value: requirement.id,
			})),
		)
)