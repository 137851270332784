import React from 'react'
import { Table, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { Badge } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import Pagination from '../../../components/Pagination'

export default function ServiceReportsTable({ serviceReports, reload, pagination, setPagination }) {
	
	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			render: t => <Link to={`/service_reports/${t}`}>{t}</Link>
		},
		{
			title: 'Empresa',
			render: (t, r) => r.service_review.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch.company.name
		},
		{
			title: 'Instalación',
			render: (t, r) => r.service_review.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch.name
		},
		{
			title: 'Tipo Instalación',
			render: (t, r) => r.service_review.service_plan.service_inspector.service.proposal.inquiry.branch_spec.branch.instalation_type_name
		},
		{
			title: 'Propuesta',
			render: (t, r) => r.service_review.service_plan.service_inspector.service.proposal.sku
		},
		{
			title: 'Inspector Insp. Cierre',
			render: (t, r) => r.service_review.service_plan.service_inspector.service.type === 'Cierre' ? r.service_review.service_plan.service_inspector.user_name : <em>N/A</em>
		},
		{
			title: 'Estado: Director',
			dataIndex: 'is_director_compliant',
			render: t => t === null ? <Badge color="warning">Pendiente</Badge> : t === 1 ? <Badge color="success">Conforme</Badge> : <Badge color="danger">No Conforme</Badge>
		},
		{
			title: 'Estado: Comite',
			dataIndex: 'is_committee_compliant',
			render: t => t === null ? <Badge color="warning">Pendiente</Badge> : t === 1 ? <Badge color="success">Conforme</Badge> : <Badge color="danger">No Conforme</Badge>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					<Tooltip title="Ver Informe">
						<Link to={`/service_reports/${r.id}`}>
							<FontAwesomeIcon icon={faSearch} className='text-link' />
						</Link>
					</Tooltip>
				</React.Fragment>
			)
		}
	]
	
	return (
		<React.Fragment>
			<Table 
				dataSource={serviceReports}
				loading={!serviceReports}
				columns={columns}
				rowKey={r => r.id}
				size='small'
				pagination={false}
				className="mb-15"
			/>
			<Pagination
				reload={reload}
				pagination={pagination}
				updatePagination={setPagination}		
			/>
		</React.Fragment>
	)
}