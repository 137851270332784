import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Spinner } from 'reactstrap'

import VerificationListDocument from './partials/VerificationListDocument'
import InternetRequired from '../Offline/partials/InternetRequired'

import { getVerificationList } from './services'
import { handleError } from '../../helpers'
import { getVerificationTasks } from '../VerificationTasks/services'

export default function VerificationListPreview(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.id ? props.id : props.match.params.id
	const [verificationList, setVerificationList] = useState(null)
	const [verificationTasks, setVerificationTasks] = useState(null)

	useEffect(() => {
		!verificationList && isConnected && getVerificationList(id, {
			include: 'service.proposal.inquiry.branchSpec.branch',
			append: 'proposal_sku'
		})
			.then(res => setVerificationList(res.data.data))
			.catch(error => handleError(error))
	}, [ verificationList, id, isConnected ])

	useEffect(() => {
		!verificationTasks && verificationList && getVerificationTasks({
			per_page: 10000,
			'filter[verification_list_id]': verificationList.id,
			include: 'taskTemplate',
			sort: 'sku',
		})
			.then(res => setVerificationTasks(res.data.data))
			.catch(error => handleError(error))
	}, [ verificationTasks, verificationList ])

	if(!isConnected) return <InternetRequired />

	if(!verificationList || !verificationTasks) return <Spinner />

	return (
		<React.Fragment>
			<Container>
				<VerificationListDocument 
					data={{ ...verificationList, verification_tasks: verificationTasks }} 
				/>
			</Container>
		</React.Fragment>
	)
}

const Container = styled.div`
	padding: 0px 10px;
`