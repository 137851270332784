import React from 'react'
import { Empty, message, Popconfirm } from 'antd'
import styled from 'styled-components'
import { Button, Col, Row } from 'reactstrap'
// import moment from 'moment'

import { deleteReportEvidence } from '../services'
import { handleError } from '../../../helpers'

export default function ReportEvidenceGrid({ reportEvidence, reload, editable }) {

	const handleDelete = id => {
		deleteReportEvidence(id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			{ reportEvidence.length > 0 ? (
				<React.Fragment>
					{ reportEvidence.map( evidence => (
						<EvidenceContainer key={evidence.id}>
							<Row>
								<Col className='bg-light text-center'>
									<EvidenceImage src={evidence.attachment_uri} />
								</Col>
								{/* <Col className='pt-25'>
									<p>Actualizado el: {moment(evidence.updated_at).format('DD-MMM-YYYY HH:mm')}</p>
								</Col> */}
								<Col className='text-center pt-20'>
									<Popconfirm
										title="Esta acción no se puede revertir"
										okText="Eliminar"
										cancelText="Cancelar"
										okButtonProps={{ danger: true }}
										onConfirm={() => handleDelete(evidence.id)}
									>
										<Button size="sm" color="danger" disabled={!editable} outline>Eliminar</Button>
									</Popconfirm>
								</Col>
							</Row>
						</EvidenceContainer>
					)) }
				</React.Fragment>
			) : (
				<Empty 
					description="No tiene registros fotográficos ingresados" 
					imageStyle={{ height: '70px' }} 
					className='mb-20'
				/>
			)}
		</React.Fragment>
	)
}

const EvidenceContainer = styled.div`
	margin-bottom: 15px;
`

const EvidenceImage = styled.img`
	max-height: 70px;
`