import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { Button } from 'reactstrap'
import { currency } from '../../../helpers'
import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

export default function ProposalForm({ form, setForm, errors, handleSubmit }) {
	return (
		<React.Fragment>
			<InputGroup>
				<InputGroup.Text>CONSECUTIVO</InputGroup.Text>
				<InputGroup.Text>PRN-</InputGroup.Text>
				<Form.Control
					type="number"
					value={form.sku_key}
					onChange={e => setForm({ ...form, sku_key: e.target.value })}
				/>
				{ errors.sku_key && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</InputGroup>
			<Form.Group>
				<Form.Label>Versión</Form.Label>
				<FormatVersionSelect 
					keyword="proposal"
					onChange={(version, expire_at) => setForm({ ...form, version, expire_at })}
					placeholder='Escriba aquí...'
				/>
				{ errors.version && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha Vigencia</Form.Label>
				<Form.Control 
					value={form.expire_at}
					disabled
				/>
				{ errors.expire_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Fecha Elaboración</Form.Label>
				<Form.Control 
					value={form.elaborated_at}
					onChange={e => setForm({ ...form, elaborated_at: e.target.value })}
					type="date"
				/>
				{ errors.elaborated_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Valor Servicio Inspección</Form.Label>
				<Form.Control 
					value={form.service_value}
					onChange={e => setForm({ ...form, service_value: e.target.value })}
					type="number"
				/>
				{ errors.service_value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Total Gastos de Viaje <small>(opcional)</small></Form.Label>
				<Form.Control 
					value={form.trip_expenses_value}
					onChange={e => setForm({ ...form, trip_expenses_value: e.target.value })}
					type="number"
				/>
				{ errors.trip_expenses_value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Valor Total</Form.Label>
				<Form.Control value={currency(parseInt(form.service_value) + parseInt(form.trip_expenses_value))} disabled />
			</Form.Group>
			<Form.Group>
				<Form.Label>Valor Total en Letras</Form.Label>
				<Form.Control 
					value={form.text_value}
					onChange={e => setForm({ ...form, text_value: e.target.value })}
					placeholder='Escriba aquí...'
				/>
				{ errors.text_value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Términos y condiciones del precio</Form.Label>
				<Form.Control 
					value={form.price_terms}
					onChange={e => setForm({ ...form, price_terms: e.target.value })}
					placeholder='Escriba aquí...'
					as="textarea"
					style={{ height: '200px' }}
				/>
				{ errors.price_terms && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>¿Es Dominguez Sanchez SAS el constructor?</Form.Label>
				<div>
					<Form.Check 
						type="radio" 
						checked={form.is_ds_construction}
						label="Sí" 
						onChange={e => setForm({ ...form, is_ds_construction: 1 })} 
						inline
					/>
					<Form.Check 
						type="radio" 
						checked={!form.is_ds_construction}
						label="No" 
						onChange={e => setForm({ ...form, is_ds_construction: 0 })}
						inline
					/>
				</div>
			</Form.Group>
			<Button color="primary" onClick={handleSubmit} className='mt-15'>
				Enviar Propuesta
			</Button>
		</React.Fragment>
	)
}