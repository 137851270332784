import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, Spinner } from 'reactstrap'
import { Tabs } from 'antd'

import ReportProfileTopBar from './partials/ReportProfileTopBar'
import ServiceProfileDetails from '../Services/partials/ServiceProfileDetails'
import InternetRequired from '../Offline/partials/InternetRequired'

import ServiceReportTab from './ServiceReportTab'
import ServiceVeredictTab from '../ServiceVeredicts/ServiceVeredictTab'
import CertificateTab from '../Certificates/CertificateTab'
import DecisionNoticeTab from '../DecisionNotices/DecisionNoticeTab'
import RecertificationProposalTab from '../Proposals/RecertificationProposalTab'

import { getServiceReport } from './services'
import { handleError } from '../../helpers'

const { TabPane } = Tabs

export default function ServiceReportProfile(props) {
	const { isConnected } = useSelector(state => state.offline)
	const id = props.match.params.id
	const user = useSelector(state => state.auth.user)
	const [serviceReport, setServiceReport] = useState(null)
	const [currentTab, setCurrentTab] = useState('informe')

	useEffect(() => {
		!serviceReport && isConnected && getServiceReport(id, {
			include: 'serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.branchSpec.branch.company,serviceReview.servicePlan.serviceInspector.service.proposal.inquiry.employee,serviceReview.servicePlan.serviceInspector.user,serviceReview.tankSpecs,serviceReview.islandSpecs,serviceReview.reviewEquipments,serviceVeredict.certificate,serviceVeredict.decisionNotice,reportEvidence',
		})
			.then(res => setServiceReport(res.data.data))
			.catch(error => handleError(error))
	}, [ serviceReport, id, isConnected ])

	if(!isConnected) return <InternetRequired />

	if(!serviceReport) return <Spinner />

	const reload = () => setServiceReport(null)

	return (
		<React.Fragment>
			<ReportProfileTopBar serviceReport={serviceReport} reload={reload} />
			<Card>
				<CardBody>
					<Tabs tabPosition='left' type="card" activeKey={currentTab} onChange={key => setCurrentTab(key)}>
						<TabPane key="informe" tab="1. Informe de Inspección">
							<h6 className='mt-10 mb-0 text-center'>Informe de Inspección</h6>
							<ServiceReportTab serviceReport={serviceReport} reload={reload} />
						</TabPane>
						{ user.role !== 'inspector' && (
							<React.Fragment>
								<TabPane key="revision" tab="2. Revisión y Decisión">
									<h6 className='mt-10 mb-0 text-center'>Revisión y Decisión</h6>
									<ServiceVeredictTab serviceReport={serviceReport} reload={reload} />
								</TabPane>
								<TabPane 
									key="certificado" 
									tab="3. Certificado de Inspección"
									disabled={!serviceReport.service_veredict}
								>
									<h6 className='mt-10 mb-0 text-center'>Certificado de Inspección</h6>
									<CertificateTab serviceReport={serviceReport} reload={reload} />
								</TabPane>
								<TabPane 
									key="carta" 
									tab="4. Comunicado de Decisión"
									disabled={serviceReport.is_committee_compliant === null}
								>
									<h6 className='mt-10 mb-0 text-center'>Comunicado Decisión Proceso de Inspección</h6>
									<DecisionNoticeTab serviceReport={serviceReport} reload={reload} />
								</TabPane>
								<TabPane 
									key="recertificacion" 
									tab="5. Propuesta de Recertificación"
									disabled={!(serviceReport.service_veredict && serviceReport.service_veredict.decision_notice && user.role === 'admin')}
								>
									<h6 className='mt-10 mb-0 text-center'>Propuesta Recertificación</h6>
									<RecertificationProposalTab serviceReport={serviceReport} reload={reload} />
								</TabPane>
							</React.Fragment>
						)}
					</Tabs>
				</CardBody>
			</Card>
			<ServiceProfileDetails 
				service={serviceReport.service_review.service_plan.service_inspector.service} 
				reload={() => setServiceReport(null)}
			/>
		</React.Fragment>
	)
}