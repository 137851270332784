import React from 'react'
import DocumentFooter from './DocumentFooter'
import DocumentHeader from './DocumentHeader'

export default function DocumentExtraTanks({ filters, data, branch, serviceReview, page, pageTotal }) {
	return (
		<React.Fragment>
			<div className='clearfix mb-20' />
			<div className='text-center full-width'>
				<DocumentHeader data={data} branch={branch} filters={filters} />
				<h5 className='mb-10'>ANEXO {page-1}</h5>
				<p className='small mb-7'>Descripción de la infraestructura inspeccionada:</p>
				<table className='full-width border text-center table-small mb-2'>
					<tbody>
						<tr className='bold'>
							<th className='border bg-light'>Tanque</th>
							<th className='border bg-light'>Producto Almacenado</th>
							<th className='border bg-light'>Capacidad (gal)</th>
						</tr>
						{ serviceReview.tank_specs.map((tank, i) => {
							let startIndex = page === 2 ? 7 : (page === 3 ? 27 : (page === 4 ? 47 : 67))
							let endIndex = page === 2 ? 26 : (page === 3 ? 46 : (page === 4 ? 66 : 86))
							if(i >= startIndex && i <= endIndex){
								return (
									<tr key={tank.id}>
										<td className='border'>
										{tank.tank_number}
										</td>
										<td className='border'>
											{tank.other_product_name ? tank.other_product_name : tank.product_name}
										</td>
										<td className='border'>
											{tank.tank_capacity}
										</td>
									</tr>
								)
							} else {
								return null
							}
						}) }
						{ page === pageTotal && (
							<tr>
								<td colSpan={2} className='border bold text-right pr-5'>Capacidad Total</td>
								<td className='bold'>
									{ data.tanks_total_capacity.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }
								</td>
							</tr>
						)}
					</tbody>
				</table>

				<DocumentFooter filters={filters} data={data} page={page} pageTotal={pageTotal} />
			</div>
		</React.Fragment>
	)
}