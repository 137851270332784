import React from 'react'
import QRCode from 'react-qr-code'
import { Col, Row } from 'reactstrap'
import moment from 'moment'

export default function DocumentFooter({ filters, data, page, pageTotal, children }) {
	return (
		<div className='document-footer'>
			<Row className='text-left mb-10'>
				<Col xs="3">
					{ filters.isOfficial && (
						<React.Fragment>
							<img src="/images/firma_alejandra.jpeg" alt="firma" className='img-firma' />
							<p className='bold'>Alejandra Martin</p>
							<p className='bold'>Dirección Técnica</p>
							<p className='bold'>DEWAR – OIN</p>
						</React.Fragment>
					)}
				</Col>
				<Col className='text-center pt-20' xs="3">
					{ filters.isOfficial && (
						<a href={`https://admin.dewar.com.co/#/vista_previa/certificadosof/${data.id}`} target="_blank" rel="noreferrer">
							<QRCode
								style={{ height: "80px", maxWidth: "80px", width: "80px" }}
								value={`https://admin.dewar.com.co/#/vista_previa/certificadosof/${data.id}`}
							/>
						</a>
					)}
				</Col>
				<Col xs="6" className='text-right pt-20'>
					<p style={{ fontSize: '15px', marginBottom: '5px' }}>
						<strong>FECHA DE EMISIÓN:</strong> {moment(data.issued_at).format('DD-MMM-YYYY')}
					</p>
					<p><strong>Fecha de inspección inicial:</strong> {moment(data.initial_inspection_at).format('DD-MMM-YYYY')}</p>
					{ data.retry_inspection_at && <p><strong>Fecha de inspección cierre:</strong> {moment(data.retry_inspection_at).format('DD-MMM-YYYY')}</p> }
					{ data.modified_at && <p><strong>Fecha de modificación:</strong> {moment(data.modified_at).format('DD-MMM-YYYY')}</p> }
				</Col>
			</Row>
			
			{ children }

			<div className='mb-5 mt-5'>
				<p className='xs'>Página {page}/{pageTotal}</p>
			</div>
			<div className='text-center'>
				<p className='mb-0 small bold'>Carrera 61 # 96 A 23 Oficina 201 Barrio Andes, Bogotá – Colombia. Cel. 3163702077. <br />E-mail. organismo@dewar.com.co</p>
			</div>
		</div>
	)
}