import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

export default function ServiceNotificationForm({ register, errors, setValue }) {
	return (
		<React.Fragment>
			<Row>
				<Form.Group as={Col}>
					<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
					<FormatVersionSelect 
						keyword="serviceNotification"
						setValue={setValue}
					/>
					{ errors.version && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
				</Form.Group>
				<Form.Group as={Col}>
					<Form.Label>Fecha Vigencia <span className='text-danger'>*</span></Form.Label>
					<Form.Control {...register('expire_at')} disabled />
				</Form.Group>
			</Row>
			<Form.Group>
				<Form.Label>Fecha Envío Notificación <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('notified_at', { required: true })}
					type="date"
				/>
				{ errors.notified_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Coordinador de Programación <span className='text-danger'>*</span></Form.Label>
				<Form.Control 
					{...register('sign_by', { required: true })}
					placeholder="Escriba aquí..."
				/>
				{ errors.sign_by && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}