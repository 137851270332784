import React from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { message } from 'antd'

import { BottomBar, SectionTitle, SectionContent } from '../../../../components/WizardComponents'
import FormatVersionSelect from '../../../FormatVersions/FormatVersionSelect'

export default function IntroStep({ form, setForm, currentStep, setCurrentStep }) {

	const handleNextStep = () => {
		if( !form.sku_key ) return message.warning('Diligencie Consecutivo')
		if( !form.version ) return message.warning('Diligencie el Código-Versión')
		if( !form.expire_at ) return message.warning('Diligencie la fecha de vigencia')

		setCurrentStep(currentStep+1)
	}

	return (
		<React.Fragment>			
			<Row className='mb-10'>
				<InputGroup as={Col}>
					<InputGroup.Text>CONSECUTIVO</InputGroup.Text>
					<InputGroup.Text>SSN-</InputGroup.Text>
					<Form.Control
						type="number"
						value={form.sku_key}
						onChange={e => setForm({ ...form, sku_key: parseInt(e.target.value) })}
					/>
				</InputGroup>
				<InputGroup as={Col}>
					<InputGroup.Text>CÓDIGO - VERSIÓN</InputGroup.Text>
					<FormatVersionSelect 
						keyword="inquiry"
						value={form.version}
						onChange={(version, expire_at) => setForm({ ...form, version, expire_at })}
					/>
				</InputGroup>
				<InputGroup as={Col}>
					<InputGroup.Text>FECHA VIGENCIA</InputGroup.Text>
					<Form.Control 
						value={form.expire_at}
						disabled
					/>
				</InputGroup>
			</Row>
			<SectionTitle>VERACIDAD Y SUFICIENCIA DE LA INFORMACIÓN</SectionTitle>
			<SectionContent>Esta solicitud debe diligenciarse con información veraz, declarando la totalidad de la información que se solicita, para analizar la competencia y alcance de DEWAR. Los datos suministrados en este formato serán verificados durante el proceso y corresponderán a los datos consignados en los documentos resultantes del mismo.</SectionContent>
			<SectionTitle>DECLARACIÓN DE IMPARCIALIDAD E INDEPENDENCIA</SectionTitle>
			<SectionContent>DEWAR realiza el proceso de inspección de manera imparcial e independiente de entidades relacionadas dedicadas a la propiedad, diseño, construcción / fabricación, suministro, instalación o mantenimiento de las instalaciones objeto de inspección. Las decisiones que se tomen en los procesos de inspección corresponden específicamente a los criterios del Reglamento Técnico objeto de evaluación.</SectionContent>
			<SectionTitle>DECLARACIÓN DE CONFIDENCIALIDAD</SectionTitle>
			<SectionContent>La información aquí consignada será considerada por el organismo de inspección DEWAR de CARÁCTER CONFIDENCIAL y se garantiza su uso interno aplicado exclusivamente para la elaboración de la propuesta comercial, documentación de la inspección y elaboración de informes y certificados, preparación del servicio y la prestación de este por parte de DEWAR.</SectionContent>
			<BottomBar handleNextStep={handleNextStep} />
		</React.Fragment>
	)
}