import React from 'react'
import { Form } from 'react-bootstrap'

export default function RequirementForm({ requirement, register, errors }) {
	return (
		<React.Fragment>
			<Form.Group>
				<Form.Label>Nombre <span className="text-danger">*</span></Form.Label>
				<Form.Control {...register('name', {required:true})} defaultValue={requirement && requirement.name} />
				{ errors.name && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción Corta <small>(opcional)</small></Form.Label>
				<Form.Control {...register('hint', {})} defaultValue={requirement && requirement.hint} />
				{ errors.hint && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
			<Form.Group>
				<Form.Label>Descripción Larga <small>(opcional)</small></Form.Label>
				<Form.Control 
					as="textarea"
					{...register('description', {})} 
					defaultValue={requirement && requirement.description}
				/>
				{ errors.description && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
			</Form.Group>
		</React.Fragment>
	)
}