import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { Form, Row, Col, InputGroup, Spinner } from 'react-bootstrap'
import { message } from 'antd'

import FormatVersionSelect from '../../FormatVersions/FormatVersionSelect'

import { storeCertificate } from '../services'
import { handleError } from '../../../helpers'
import { getInstalationTypes } from '../../InstalationTypes/services'

export default function NewCertificateModal({ visible, onClose, serviceVeredict, branch, reload }) {
	const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm()
	const [instalationTypes, setInstalationTypes] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		!instalationTypes && getInstalationTypes()
			.then(res => setInstalationTypes(res.data.data))
			.catch(error => handleError(error))
	}, [ instalationTypes ])

	const onSubmit = values => {
		setLoading(true)
		storeCertificate({ 
			service_veredict_id: serviceVeredict.id, 
			company_name: branch.company.name,
			company_document: branch.company.document,
			company_sicom: branch.sicom_code,
			...values
		})
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error, setLoading))
	}

	return (
		<React.Fragment>
			<Modal isOpen={visible}>
				<ModalHeader toggle={onClose}>Generar Certificado de Inspección</ModalHeader>
				<ModalBody>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Form.Group as={Col}>
								<Form.Label>Versión <span className='text-danger'>*</span></Form.Label>
								<FormatVersionSelect 
									keyword="certificate"
									setValue={setValue}
								/>
								{ errors.version && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Form.Group as={Col}>
								<Form.Label>Fecha Expedición <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									{...register('issued_at', { required: true })}
									type="date"
								/>
								{ errors.issued_at && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
						</Row>						
						<Form.Group>
							<Form.Label>Numerales Aplicables</Form.Label>
							{ instalationTypes ? (
								<React.Fragment>
									<Form.Control 
										as="select"
										{...register('article_numerals', { required: true })}
									>
										<option value="">:: Seleccione una opción ::</option>
										{ instalationTypes.map(type => <option value={type.criteria}>{type.name}</option>) }
									</Form.Control>
									<Form.Control 
										as="textarea"
										value={watch('article_numerals')}
										style={{ height: '100px' }}
										disabled
									/>
								</React.Fragment>
							) : (
								<Spinner animation='border' size="sm" />
							)}
							{ errors.article_numerals && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Consecutivo <span className='text-danger'>*</span></Form.Label>
							<InputGroup>
								<InputGroup.Text>CERT-</InputGroup.Text>
								<Form.Control 
									type="number"
									{...register('sku', { required: true })}
								/>
								<InputGroup.Text>-YYYY</InputGroup.Text>
							</InputGroup>
							{ errors.sku && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Form.Group>
							<Form.Label>Observaciones <small>(opcional)</small></Form.Label>
							<Form.Control 
								as="textarea"
								{...register('observations')}
								style={{ height: '70px' }}
								defaultValue="En la inspección se incluyen de estos numerales aquellos que apliquen de acuerdo con las especificaciones de la instalación. Es responsabilidad de la instalación el cumplimiento de los requisitos que tienen plazo para su cumplimiento de acuerdo con la Resolución 40198 de 2021."
							/>
						</Form.Group>
						{/* <Form.Group>
							<Form.Label>URL Adjunto <small>(opcional)</small></Form.Label>
							<Form.Control 
								type="url"
								{...register('attachment_url')}
							/>
						</Form.Group> */}
						<Button color="primary" type="submit" disabled={loading}>
							{ loading && <Spinner animation='border' size="sm" className='mr-5' /> }
							Generar Certificado
						</Button>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	)
}