import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Empty, Table, Tooltip, Descriptions, Divider, Popconfirm, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons'

import EditVerificationTaskModal from '../../VerificationTasks/partials/EditVerificationTaskModal'
import Pagination from '../../../components/Pagination'
import { deleteVerificationTask } from '../../VerificationTasks/services'
import { handleError } from '../../../helpers'
import DeleteOfflineTaskAction from '../../Offline/partials/DeleteOfflineTaskAction'

const { Item } = Descriptions

export default function VerificationListTasksTable({ 
	verificationList, 
	verificationTasks, 
	reloadTasks, 
	editable, 
	isRetry, 
	pagination, 
	setPagination, 
	updateTasks,
	serviceId
}) {
	const user = useSelector(state => state.auth.user)
	const { isConnected } = useSelector(state => state.offline)
	const [editTaskModal, setEditTaskModal] = useState(null)
	
	const columns = [
		{
			title: 'Item',
			dataIndex: 'task_template',
			render: t => t.sku,
		},
		{
			title: 'Descripción',
			dataIndex: 'task_template',
			render: t => t.legal_description
		},
		{
			title: 'Estado',
			dataIndex: 'status',
		},
		{
			title: 'Evidencia',
			dataIndex: 'evidence_items',
			render: t => isConnected ? t : <em>Requiere internet</em>
		},
		{
			title: 'Acciones',
			render: (t, r) => (
				<React.Fragment>
					{ editable && (
						<React.Fragment>
							{ r.id ? (
								<Tooltip title="Editar">
									<FontAwesomeIcon 
										icon={faEdit} 
										className='text-link'
										onClick={() => setEditTaskModal(r)}
									/>
								</Tooltip>
							) : (
								<DeleteOfflineTaskAction 
									task={r} 
									serviceId={serviceId} 
									reloadTasks={reloadTasks}
								/>
							)}
						</React.Fragment>
					)}
					{ user.role === 'admin' && false && ( 
						<React.Fragment>
							<Divider type="vertical" />
							<Tooltip title="Eliminar">
								<Popconfirm
									title="Se eliminara esta tarea de la inspección incial o en la de cierre según donde se encuentre en este momento. Tanto en lista de verificación como en el reporte de no conformidades. Esta acción no se puede revertir."
									okText="Eliminar"
									okButtonProps={{ danger:true }}
									onConfirm={() => handleDelete(r.id)}
								>
									<FontAwesomeIcon 
										icon={faTrash} 
										className='text-link'
									/>
								</Popconfirm>
							</Tooltip>
						</React.Fragment>
					)}
				</React.Fragment>
			)
		}
	]

	const expandedRowRender = record => (
		<Descriptions bordered size="small" column={1}>
			<Item label="Observaciones">{record.observations}</Item>
			{ record.observations_retry && <Item label="Observaciones de Cierre">{record.observations_retry}</Item> }
			<hr />
			<Item label="Artículo">{record.task_template.article}</Item>
			<Item label="Aplicación para Instalaciones">{record.task_template.instalation_period}</Item>
			<Item label="Plazo">{record.task_template.description}</Item>
			<Item label="Características Minminas">{record.task_template.minminas_criteria}</Item>
			<Item label="Evidencia Minminas">{record.task_template.general_criteria}</Item>
		</Descriptions>
	)

	const handleDelete = id => {
		deleteVerificationTask(id)
			.then(res => message.success(res.data.message))
			.then(() => reloadTasks())
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Table 
				dataSource={verificationTasks ? verificationTasks : verificationList.verification_tasks}
				rowKey={r => r.id}
				columns={columns}
				expandable={{ expandedRowRender }}
				size="small"
				locale={{ emptyText: <Empty description="No tiene tareas registradas" imageStyle={{ height: '70px' }} /> }}
				className='mb-10'
				pagination={!pagination}
			/>
			{ pagination && (
				<Pagination
					reload={reloadTasks}
					pagination={pagination}
					updatePagination={setPagination}
				/>
			)}
			{editTaskModal && (
				<EditVerificationTaskModal 
					visible
					onClose={() => setEditTaskModal(null)}
					verificationTask={editTaskModal}
					reload={updateTasks}
					isRetry={isRetry}
					serviceId={serviceId}
				/>
			)}
		</React.Fragment>
	)
}