import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import { Descriptions, Divider, Empty, message, Popconfirm } from 'antd'

import ReviewSecurityModal from './partials/ReviewSecurityModal'
import NewServiceReviewModal from './partials/NewServiceReviewModal'

import ServiceReviewSpecsList from '../ReviewSpecs/ServiceReviewSpecsList'
import ReviewEquipmentsTable from '../ReviewEquipments/partials/ReviewEquipmentsTable'
import ReviewImpartialityNoticeModal from '../ImpartialityNotice/ReviewImpartialityNoticeModal'
import ImpartialityNoticeAttachmentModal from '../ImpartialityNotice/partials/ImpartialityNoticeAttachmentModal'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'

import SendNotificationButton from '../../components/SendNotificationButton'

import { deleteServiceReview, notifyServiceReview } from './services'
import { handleError } from '../../helpers'

const { Item } = Descriptions

export default function ServiceReviewTab({ service, reload }) {
	const { isConnected } = useSelector(state => state.offline)
	const [showNewModal, setShowNewModal] = useState(false)
	const [showSecurityModal, setShowSecurityModal] = useState(false)
	const [showImpartialityNoticeModal, setShowImpartialityNoticeModal] = useState(false)
	const [showImpartialityAttachmentModal, setShowImpartialityAttachmentModal] = useState(false)
	const [emailLogsModal, setEmailLogsModal] = useState(null)

	const serviceReview = service.service_inspector.service_plan.service_review || null
	const verificationList = service.verification_list

	const handleDelete = () => {
		deleteServiceReview(serviceReview.id)
			.then(res => {
				message.success(res.data.message)
				reload()
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			{ serviceReview ? (
				<React.Fragment>
					<p className='bold pt-10'>INFORMACIÓN DE SEGURIDAD</p>
					<Descriptions bordered column={2} size='small' className='mb-15'>
						<Item label="1. ¿Conoce las acciones que se deben adelantar en caso de emergencia?" span={2}>
							{ parseInt(serviceReview.has_security_actions) ? 'Si' : 'No' }
						</Item>
						<Item label="2. ¿Se cuenta con las condiciones necesarias para realizar la inspección de manera segura?" span={2}>
							{ parseInt(serviceReview.has_security_conditions) ? 'Si' : 'No' }
						</Item>
						<Item label="3. ¿Requiere de algún elemento adicional para realizar la inspección de forma segura?	" span={2}>
							{ parseInt(serviceReview.has_security_additionals) ? 'Si' : 'No' }
						</Item>
					</Descriptions>
					{ service.is_editable && (
						<div className='text-right mb-20'>
							<p className='text-link inline' onClick={() => setShowSecurityModal(true)}>
								Modificar respuestas y observaciones de seguridad
							</p>
						</div>
					)}
					<p className='bold mt-10'>CARACTERÍSTICAS TÉCNICAS DE LA INSTALACIÓN</p>
					<ServiceReviewSpecsList service={service} serviceReviewId={serviceReview.id} />
					<p className='bold mt-10'>EQUIPOS DE MEDICIÓN EMPLEADOS EN LA INSPECCIÓN</p>
					<ReviewEquipmentsTable reviewEquipments={serviceReview.review_equipments} />
					<p className='bold mt-10'>RATIFICACIÓN DEL PRINCIPIO DE IMPARCIALIDAD E INDEPENDENCIA</p>
					<Descriptions bordered column={2} size='small' className='mb-15'>
						<Item style={{ wordBreak: 'normal' }} label="Si la instalación ha recibido servicios por alguna de las entidades relacionadas identificadas previamente por  DEWAR, escriba el nombre y diligencie la Ratificación del principio de imparcialidad e independencia FRN34">
							{serviceReview.impartiality_notice ? serviceReview.impartiality_notice.inspector_name : 'NA'}
						</Item>
					</Descriptions>
					{ service.is_editable && (
						<div className='text-right mb-20'>
							<p className='text-link inline' onClick={() => setShowImpartialityNoticeModal(true)}>
								Modificar ratificación de imparcialidad
							</p>
						</div>
					)}
					{ serviceReview.impartiality_notice && isConnected && (
						<React.Fragment>
							<div className='text-right mb-20'>
								<p className='text-link' onClick={() => setShowImpartialityAttachmentModal(serviceReview.impartiality_notice)}>
									Adjunto de Formato de Ratificación
								</p>
							</div>
							<div className='text-right mb-20'>
								<Link to={`/impartiality_notices/preview/${serviceReview.id}`} target="_blank">
									Ver Formato de Ratificación
								</Link>
							</div>
						</React.Fragment>
					)}
					<Link to={isConnected ? `/service_reviews/preview/${serviceReview.id}` : `/offline/service_reviews/preview/${service.id}/${serviceReview.id}`}>
						<Button color="primary" outline>Formato de Acta de Inspección</Button>
					</Link>
					<Divider type="vertical" />
					<SendNotificationButton 
						notifyService={()=>notifyServiceReview(serviceReview.id)} 
						offlineRequest={{method: 'POST', url: `/service_reviews/${serviceReview.id}/notify`}}
					/>
					<Divider type="vertical" />
					<Button color="primary" outline onClick={() => setEmailLogsModal(serviceReview.id)} disabled={!isConnected}>
						Correos Enviados
					</Button>
					{ !verificationList && (
						<React.Fragment>
							<Divider type="vertical" />
							<Popconfirm
								title="Esta acción no se puede reversar"
								okText="Eliminar"
								cancelText="Cancelar"
								okButtonProps={{ danger:true }}
								onConfirm={handleDelete}
							>
								<Button color="primary" outline>Eliminar Acta de Inspección</Button>
							</Popconfirm>
						</React.Fragment>
					)}
				</React.Fragment>
			) : (
				<Empty description="No se tiene una acta de inspección aún" className='pt-50'>
					<Button color="primary" onClick={() => setShowNewModal(true)}>
						Iniciar Acta de Inspección
					</Button>
				</Empty>
			)}
			{ showNewModal && (
				<NewServiceReviewModal 
					visible
					onClose={() => setShowNewModal(false)}
					servicePlanId={service.service_inspector.service_plan.id}
					reload={reload}
				/>
			)}
			{ showSecurityModal && (
				<ReviewSecurityModal 
					visible
					onClose={() => setShowSecurityModal(false)}
					serviceReview={serviceReview}
					serviceId={service.id}
					reload={reload}
				/>
			)}
			{ showImpartialityNoticeModal && (
				<ReviewImpartialityNoticeModal 
					visible
					onClose={() => setShowImpartialityNoticeModal(false)}
					serviceReview={serviceReview}
					serviceId={service.id}
					reload={reload}
				/>
			)}
			{ showImpartialityAttachmentModal && (
				<ImpartialityNoticeAttachmentModal 
					visible
					onClose={() => setShowImpartialityAttachmentModal(null)}
					impartialityNotice={showImpartialityAttachmentModal}
					reload={reload}
				/>
			)}
			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="service_review"
					modelId={emailLogsModal}
				/>
			)}
		</React.Fragment>
	)
}