import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { Card, CardBody, CardHeader, CardTitle, CardSubtitle, Spinner } from 'reactstrap'
import { message } from 'antd'

import ProposalForm from './partials/ProposalForm'
import ProposalFormPreview from './partials/ProposalFormPreview'
import InternetRequired from '../Offline/partials/InternetRequired'

import { storeProposal } from './services'
import { handleError } from '../../helpers'
import { getInquiry } from '../Inquiries/services'

export default function ProposalWizard(props) {
	const { isConnected } = useSelector(state => state.offline)
	const history = useHistory()
	const inquiryId = props.match.params.inquiryId
	const certificateId = props.match.params.certificateId
	const [inquiry, setInquiry] = useState(null)
	const [errors, setErrors] = useState({})
	const [form, setForm] = useState({
		sku_key: '',
		version: '',
		expire_at: '',
		elaborated_at: '',
		service_value: 0,
		trip_expenses_value: 0,
		text_value: '',
		price_terms: `- El precio de gastos de viaje puede variar, en el caso que por conveniencia del cliente asume directamente el costo de tiquetes aéreos. - En caso de que el cliente asuma el costo de tiquetes se realizará la variación en el total del servicio en la correspondiente cuenta de cobro/factura. - Los costos del tiquete están sujetos a variaciones de acuerdo con la programación que se realice. - El precio total por cancelar es el Total del servicio de inspección más IVA`,
		is_ds_construction: 0
	})

	useEffect(() => {
		!inquiry && isConnected && getInquiry(inquiryId, {
			include: 'branchSpec.branch.instalationType,employee'
		})
			.then(res => setInquiry(res.data.data))
			.catch(error => handleError(error))
	}, [inquiryId, inquiry, isConnected])

	if(!isConnected) return <InternetRequired />

	if(!inquiry) return <Spinner />

	const handleSubmit = () => {
		if(!form.sku_key) return setErrors({ sku_key: true })
		if(!form.version) return setErrors({ version: true })
		if(!form.expire_at) return setErrors({ expire_at: true })
		if(!form.elaborated_at) return setErrors({ elaborated_at: true })
		if(!form.service_value) return setErrors({ service_value: true })
		if(!form.text_value) return setErrors({ text_value: true })
		if(!form.price_terms) return setErrors({ price_terms: true })
		
		storeProposal({ ...form, inquiry_id: inquiryId, previous_certificate_id: certificateId })
			.then(res => {
				message.success(res.data.message)
				history.push(`/proposals/preview/${res.data.data.id}`)
			})
			.catch(error => handleError(error))
	}

	return (
		<React.Fragment>
			<Row>
				<Col md="12" lg="4">
					<Card>
						<CardHeader>
							<CardTitle>Propuesta {!certificateId ? 'Comercial' : 'Recertificación'}</CardTitle>
							<CardSubtitle>Para solicitud de servicio SDS-0{inquiryId}</CardSubtitle>
						</CardHeader>
						<CardBody>
							<ProposalForm 
								form={form}
								setForm={setForm}
								errors={errors}
								handleSubmit={handleSubmit}
							/>
						</CardBody>
					</Card>
				</Col>
				<Col md="12" lg="8">
					<Card>
						<CardBody>
							<ProposalFormPreview 
								form={form}
								inquiry={inquiry}
								isRecertification={certificateId}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}