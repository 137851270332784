import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { Descriptions, Divider, Empty } from 'antd'
import moment from 'moment'

import NewServicePlanModal from './partials/NewServicePlanModal'
import EditServicePlanModal from './partials/EditServicePlanModal'
import ServicePlanTasksList from '../PlanTasks/ServicePlanTasksList'
import EmailLogsModal from '../EmailLogs/EmailLogsModal'
import RelatedFormatsModal from '../Services/partials/RelatedFormatsModal'

import SendNotificationButton from '../../components/SendNotificationButton'

import { notifyServicePlan } from './services'

const { Item } = Descriptions

export default function ServicePlanTab({ service, reload }) {
	const [showNewModal, setShowNewModal] = useState(false)
	const [editModal, setEditModal] = useState(false)
	const [emailLogsModal, setEmailLogsModal] = useState(null)
	const [showRelatedFormatsModal, setShowRelatedFormatsModal] = useState(false)

	const servicePlan = service.service_inspector.service_plan || null

	return (
		<React.Fragment>
			{ servicePlan ? (
				<React.Fragment>
					<p className='bold pt-10'>DETALLES DEL PLAN DE INSPECCIÓN</p>
					<Descriptions bordered className='mb-15' size='small' column={2}>
						<Item label="Versión">
							{servicePlan.version}
						</Item>
						<Item label="Fecha Vigencia">
							{moment(servicePlan.expire_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
						<Item label="Fecha de la Inspección">
							{moment(servicePlan.inspected_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
						<Item label="Fecha envío del plan">
							{moment(servicePlan.sent_at).format('DD-MMM-YYYY').toUpperCase()}
						</Item>
						<Item span={2} label="Lugar">
							{servicePlan.location}
						</Item>
						<Item span={2} label="Observaciones">
							{servicePlan.observations}
						</Item>
					</Descriptions>
					{ service.related_service && (
						<Button className='mb-10' color="primary" outline onClick={() => setShowRelatedFormatsModal(true)}>
							Formatos Servicio Relacionado
						</Button>
					)}

					<p className='bold pt-10'>ACTIVIDADES POR EVALUAR</p>
					<ServicePlanTasksList 
						service={service}
						servicePlanId={servicePlan.id}
					/>
					<Link to={`/service_plans/preview/${servicePlan.id}`}>
						<Button className='mb-5' color="primary" outline>Formato de Plan de Inspección</Button>
					</Link>
					<Divider type="vertical" />
					<Button className='mb-5' color="primary" outline onClick={()=>setEditModal(true)}>
						Editar Plan de Inspección
					</Button>
					<Divider type="vertical" />
					<SendNotificationButton notifyService={() => notifyServicePlan(servicePlan.id)} />
					<Divider type="vertical" />
					<Button className='mb-5' color="primary" outline onClick={() => setEmailLogsModal(servicePlan.id)}>
						Correos Enviados
					</Button>
				</React.Fragment>
			) : (
				<Empty description="No se tiene un plan de inspección aún" className='pt-50'>
					<Button color="primary" onClick={() => setShowNewModal(true)}>
						Crear Plan de Inspección
					</Button>
				</Empty>
			)}
			{ showNewModal && (
				<NewServicePlanModal 
					visible
					onClose={() => setShowNewModal(false)}
					service={service}
					reload={reload}
				/>
			)}
			{ service.service_inspector && editModal && (
				<EditServicePlanModal 
					visible
					onClose={() => setEditModal(false)}
					servicePlan={servicePlan}
					reload={reload}
				/>
			)}
			{ emailLogsModal && (
				<EmailLogsModal
					visible
					onClose={() => setEmailLogsModal(null)}
					modelType="service_plan"
					modelId={emailLogsModal}
				/>
			)}
			{ showRelatedFormatsModal && (
				<RelatedFormatsModal
					visible
					onClose={() => setShowRelatedFormatsModal(false)}
					relatedService={service.related_service}
				/>
			)}
		</React.Fragment>
	)
}